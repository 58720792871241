import { useMemo } from "react";
import { api } from "../api-client";
import AsyncMultiSelectWidget from "../components/form-widgets/AsyncMultiSelectWidget"
import { defaults } from "lodash-es";

type SchemaProps = {
  mode: "create" | "edit" | "preview";
}
function isValidUSZip(sZip: string) {
  return /^\d{5}$/.test(sZip);
}

function iif(condition: boolean, then: any, otherwise: any = undefined) {
  return condition ? then : otherwise;
}

//const portalType = getPortalType();

const getProviderProfileSchema = ({ mode }: SchemaProps) => (

  {
    type: "object",
    required: [

    ],
    properties: {
      status: {
        "title": "Service Provider Status.",
        "type": "string",
        "oneOf": [
          {
            "const": "approved",
            "title": "Approved"
          },
          {
            "const": "pending",
            "title": "Pending"
          }
        ]
      },
      languages: {
        type: "array",
        title: "Languages",
        description: "Select language(s) supported for sessions",
        uniqueItems: true,
        items: {
          type: "object"
        },
      },
      npi: {
        "type": ["string", "null"],
        title: "NPI",
        description: "NPI Number",
      },
      states: {
        type: "array",
        title: "States with License",
        description: "States where licensed to practice",
        uniqueItems: true,
        items: {
          type: "object"

        }
      },
      serviceTypes: {
        type: "array",
        title: "Service Types",
        description: "Select Service Type(s)",
        uniqueItems: true,
        items: {
          type: "object"
        },
      },
      service_provider_types: {
        type: "array",
        title: "Service Provider Type",
        description: "Select Service Provider Type(s)",
        uniqueItems: true,
        items: {
          type: "object"
        },
      }
    }
  });

const getProviderProfileUISchema = ({
  mode,
}: SchemaProps) => ({

  "ui:submitButtonOptions": {
    norender: mode === "preview",
  },

  "ui:layoutOptions": { gridColumnProps: { xs: 12 } },

  status: {
    "ui:options": {
      placeholder: "Provider Approval Status"
    }
  },

  states: {
    "ui:widget": AsyncMultiSelectWidget,
    "ui:readonly": false,
    "ui:options": {
      placeholder: "Select States",
    },
    requestCacheKey: "states",
    request: () => api.auth.fetchStatesforReferrals(),
    selectItemOnLoad: true
  },

  serviceTypes: {
    "ui:widget": AsyncMultiSelectWidget,
    "ui:readonly": false,
    "ui:options": {
      placeholder: "Select Service Type(s)",
    },
    requestCacheKey: "serviceTypes",
    request: () => api.common.fetchServiceTypes(),
    selectItemOnLoad: true
  },

  service_provider_types: {
    "ui:widget": AsyncMultiSelectWidget,
    "ui:readonly": false,
    "ui:options": {
      placeholder: "Select Service Provider Type(s)",
    },
    requestCacheKey: "serviceProviderTypes",
    request: () => api.common.fetchServiceProviderTypes(),
    selectItemOnLoad: true
  },

  languages: {
    "ui:widget": AsyncMultiSelectWidget,
    "ui:readonly": false,
    "ui:options": {
      placeholder: "Select Language(s)",
    },
    requestCacheKey: "languages",
    request: () => api.common.fetchLanguages(),
    selectItemOnLoad: true
  },

}
);

function customValidate(formData: any, errors: any) {

  return errors;
}

function transformErrors(errors: any[]) {
  return errors.map((error) => {
    if (error.name === "required") {
      error.message = "Please fill in this field";
    } else if (error.name === "format") {
      error.message = "Please enter a valid value";
    }
    return error;
  });
}

export function useProviderProfileFormConfig(props: Partial<SchemaProps>) {
  const withDefaultProps = defaults(props, {
    mode: "create",
  });

  const schema = useMemo(
    () => getProviderProfileSchema(withDefaultProps),
    [withDefaultProps]
  );

  const uiSchema = useMemo(() => {
    return getProviderProfileUISchema(withDefaultProps);
  }, [withDefaultProps]);

  return {
    schema,
    uiSchema,
    customValidate,
    transformErrors
  };
}