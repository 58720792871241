import { AxiosInstance } from "axios";
import { BaseApiModule } from "./base.module";
import { unstrapiMany, unstrapiOne } from "../../helpers/strapi";
import { ServiceProviderType } from "../../types/appointment-types";

export class ServiceProvidersApiModule extends BaseApiModule {
  constructor(client: AxiosInstance) {
    super(client, "api");
  }

  find( id:number, populate:string[]=[]) {

    const _populate = populate?.length > 0  ? populate : ["user", "user.profilePicture", "states", "serviceTypes", "service_provider_types"];
    console.debug ('populate ' + _populate)
    if (!id)
      return

    return this.client
      .get(`api/service-providers/${id}`, {
        params: {
          populate: _populate
        }
      })
      .then((res) =>
        unstrapiOne<ServiceProviderType>(res.data, "serviceProvider")
      );
  }

  findMany({ populate }: { populate?: string[] } = {}) {
    return this.client
      .get(`api/service-providers`, {
        params: {
          populate
        }
      })
      .then((res) =>
        unstrapiMany<ServiceProviderType>(res.data, "serviceProvider")
      );
  }

  update(id: number, data: Partial<ServiceProviderType>) {
    console.debug ( 'updating service provider profile - ' + JSON.stringify(data))
    return this.client
      .put(`api/service-providers/${id}`, { data })
      .then((res) =>
        unstrapiOne<ServiceProviderType>(res.data, "serviceProvider")
      );
  }

  findAvailable(memberService: number) {
    return this.client
      .get(`api/service-providers/available`, {
        params: {
          memberService
        }
      })
      .then((res) =>
        unstrapiMany<ServiceProviderType>(res.data, "serviceProvider")
      );
  }
}
