import { api } from "@/apps/common/api-client";
import { FormInput, InputMasks, VerticalForm } from "@/apps/common/components/";
import {
  ServiceProviderType,
  StateType
} from "@/apps/common/types/appointment-types";
import { LegacyUserType } from "@/apps/common/api-client/api-client.types";
import EditProfileModal from "@/apps/clinic/pages/Profile/EditProfileModal";
import ProfileDetails from "@/apps/common/components/ProfileDetails";
import { useAuthStore } from "@/apps/common/store/useAuthStore";
import { yupResolver } from "@hookform/resolvers/yup";
import FeatherIcons from "feather-icons-react";
import { useEffect, useMemo, useState } from "react";
import { Button, Spinner, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import PaymentMethods from "@/apps/common/components/PaymentMethods";
//import { object, AnySchema, string } from 'yup';

import { useRequest } from "ahooks";
import { Form /*InputGroup*/ } from "react-bootstrap";
import StateSelector from "./StateSelector";

interface ServiceProviderData {
  id?: number | undefined;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  address: string;
  qualification: string;
  about?: string;
  states?: StateType[] | undefined;
  stripeId?: string | undefined;
}

const serviceProviderSchemaResolver = yupResolver(
  yup.object().shape({
    id: yup.number().optional(),
    firstName: yup.string().required("Please enter First Name"),
    lastName: yup.string().required(""),
    email: yup.string().required(""),
    phoneNumber: yup.string().required(),
    address: yup.string().required(),
    qualification: yup.string().required(),
    lic_states: yup.array().optional(),
    stripeId: yup.string().optional()
  })
);

const Profile = () => {
  const [showConfirmUpdateModal, setshowConfirmUpdateModal] = useState(false);
  const [savingProfile, setSavingProfile] = useState<boolean>(false);

  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [refreshView, setRefreshView] = useState<boolean>(false);

  const { t } = useTranslation();

  const defaultValues: ServiceProviderData = {
    id: -1,
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address: "",
    qualification: "",
    about: "",
    states: []
  };
  const [serviceProviderData, setServiceProviderData] =
    useState<ServiceProviderData>(defaultValues);

  let serviceProviderProfile: Partial<ServiceProviderType>;

  const [selected_states, setSelectedStates] = useState<StateType[]>([]);

  const refresh = () => {
    setRefreshView(true);
  };

  //const { /*fetchServiceProvider, fetchPaymentMethod,*/ paymentMethod } = useCommonStore();
  //const { fetchStates, user } = useAuthStore();

  //const [states, setStates] = useState<StateType[]>()

  //const [serviceProviderStatus, setServiceProviderStatus] = useState("");

  // const [filters, setFilters] = useState<any>();
  // //const { query, ...remainingFilters } = filters || {};
  // const { data: serviceProviders = [], loading} = useRequest(
  //   () =>
  //     api.serviceProviders.findMany(),
  //   {
  //     refreshDeps: [filters],
  //     debounceWait: 300
  //   }
  // );

  const { data: serviceProvider, loading } = useRequest(() =>
    api.serviceProviders
      .findMany({ populate: ["states", "user", "paymentProfile"] })
      .then((providers: any) => providers[0])
  );

  useMemo(() => {
    //console.debug('useMemo serviceProviders - ')
    const a = serviceProvider;
    //console.debug('a - ' + JSON.stringify(a))
    if (a) {
      const _data: ServiceProviderData = {
        id: a.id,
        address: a.address,
        qualification: a.qualification!,
        about: a.about!,
        states: a.states,
        firstName: a.user!.firstName,
        lastName: a.user!.lastName,
        email: a.user!.email,
        phoneNumber: a.user!.phoneNumber!

        //stripeId: a.attributes.paymentProfile.attributes.stripeId ? a.attributes.paymentProfile.attributes.stripeId :null
      };

      if (a.states) {
        _data.states = a.states;
      }

      setServiceProviderData(_data);
      //console.debug('_data - ' + JSON.stringify(_data))
    }
  }, [serviceProvider]);

  useEffect(() => {
    //console.debug('useEffect - ' + loading)
    if (loading == true) setDataLoaded(false);
    else setDataLoaded(true);
  }, [loading]);

  // const paymentStripeId = useMemo(() => {

  //   if (!serviceProviderData){
  //     return;
  //   }

  //   //const { attributes } = serviceProviderData;
  //   const stripeId = serviceProviderData.stripeId;
  //   return stripeId;
  // }, [serviceProviderData]);

  // useEffect(() => {
  //   if (clinicUser.length) {
  //     fetchPaymentMethod(paymentStripeId);
  //   }
  // }, [user, fetchPaymentMethod, paymentStripeId]);

  // useEffect(() => {
  //   if (!states) {
  //     fetchStates();
  //   }
  // }, [fetchStates, states]);

  //  useEffect(() => {

  //   // let a  = {
  //   //   firstName:user?.firstName,
  //   //   lastName:user?.lastName,
  //   //   email:user?.email,
  //   //   phoneNumber: user?.phoneNumber
  //   // }

  //   // setServiceProviderData(a)

  //  }, [serviceProviderData]);

  const updateProfile = async () => {
    try {
      //console.debug("Saving User Profile updates");
      setSavingProfile(true);

      const userData: Partial<LegacyUserType> = {
        firstName: serviceProviderData.firstName,
        lastName: serviceProviderData.lastName,
        phoneNumber: serviceProviderData.phoneNumber,
        email: serviceProviderData.email
      };

      updateUser(serviceProvider.user.id, userData);

      //console.debug ('selected_states - ' + JSON.stringify(selected_states))
      serviceProviderProfile = {
        address: serviceProviderData.address,
        qualification: serviceProviderData.qualification,
        about: serviceProviderData.about,
        states:
          selected_states.length > 0
            ? selected_states
            : serviceProviderData.states!
      };

      //console.debug('serviceProviderProfile -' + JSON.stringify(serviceProviderProfile))
      updateServiceProviderProfile();

      setSavingProfile(false);
      setshowConfirmUpdateModal(false);
    } catch (err) {
      console.log(err);
      setSavingProfile(false);
    }
  };

  const onCloseModal = () => {
    setshowConfirmUpdateModal(false);
  };

  const { loading: saving, runAsync: updateUser } = useRequest(
    api.auth.updateUser.bind(api.auth),
    {
      onSuccess: (result, params) => {
        try {
          useAuthStore.getState().fetchUser();
        } catch (error) {
          console.error(error);
        }
      },
      manual: true
    }
  );

  const { data: updateResponse, run: updateServiceProviderProfile } =
    useRequest(
      () =>
        api.serviceProviders.update(
          serviceProvider!.id,
          serviceProviderProfile
        ),
      {
        manual: true
      }
    );

  const onSubmit = (formData: ServiceProviderData) => {
    console.debug('Form Data - ' + JSON.stringify(formData))
    setServiceProviderData(formData);
    setshowConfirmUpdateModal(true);
  };

  const profileForm =
    loading == false && serviceProvider ? (
      <VerticalForm<ServiceProviderData>
        onSubmit={onSubmit}
        resolver={serviceProviderSchemaResolver}
        defaultValues={serviceProviderData}
        formClass="authentication-form row"
      >
        <FormInput
          label={t("First name")}
          type="text"
          name="firstName"
          startIcon={<FeatherIcons icon={"user"} className="icon-dual" />}
          placeholder={t("Your first name")}
          containerClass={"mb-2 col-sm-12 col-md-12 col-lg-6"}
        />

        <FormInput
          label={t("Last name")}
          type="text"
          name="lastName"
          startIcon={<FeatherIcons icon={"user"} className="icon-dual" />}
          placeholder={t("Your last name")}
          containerClass={"mb-2 col-sm-12 col-md-12 col-lg-6"}
        />

        <FormInput
          label={t("Address")}
          type="text"
          name="address"
          startIcon={<FeatherIcons icon={"map-pin"} className="icon-dual" />}
          placeholder={t("Your address")}
          containerClass={"mb-2 col-sm-12 col-md-12 col-lg-12"}
        />

        <FormInput
          label={t("Email Address")}
          type="string"
          name="email"
          startIcon={<FeatherIcons icon={"mail"} className="icon-dual" />}
          placeholder={t("hello@domain.com")}
          containerClass={"mb-2 col-sm-12 col-md-12 col-lg-6"}
        />

        <InputMasks
          type="mask"
          startIcon={<FeatherIcons icon={"phone"} className="icon-dual" />}
          label={t("Mobile Phone")}
          mask={[
            "(",
            /[1-9]/,
            /\d/,
            /\d/,
            ")",
            " ",
            /\d/,
            /\d/,
            /\d/,
            "-",
            /\d/,
            /\d/,
            /\d/,
            /\d/
          ]}
          placeholder="(___) ___-____"
          name="phoneNumber"
          containerClass={"mb-2 col-sm-12 col-md-12 col-lg-6"}
        />

        {/* {loading == false && serviceProvider && (
          <Form.Group className={"mb-2 col-sm-12 col-md-12 col-lg-6"}>
            <Form.Label>States where you are licensed</Form.Label>
            <StateSelector
              name="lic_states"
              value={serviceProvider.states}
              onChange={setSelectedStates}
            />
          </Form.Group>
        )}

        <FormInput
          label={t("Qualification")}
          type="string"
          name="qualification"
          startIcon={<FeatherIcons icon={"mail"} className="icon-dual" />}
          placeholder={t("LCSW")}
          containerClass={"mb-2 col-sm-12 col-md-12 col-lg-6"}
        /> */}

        <FormInput
          label={t("About")}
          type="textarea"
          name="about"
          startIcon={<FeatherIcons icon={"mail"} className="icon-dual" />}
          placeholder={t("Tell us about yourself")}
          containerClass={"mb-2 col-sm-12"}
        />

        <div className="my-2 text-center d-grid">
          <Button type="submit">
            {saving ? <Spinner size="sm" /> : t("Save")}
          </Button>
        </div>
      </VerticalForm>
    ) : null;

  // const serviceProviderDetails = ((loading == false) && serviceProvider) ? (
  //   <>
  //     <h4 className="mb-2 fs-15">Counsellor Service Details</h4>
  //     <div className="table-responsive">
  //       <table className="table table-borderless mb-0 text-muted">
  //         <tbody>
  //           <tr>
  //             <th scope="row">Qualifiction</th>
  //             <td>{serviceProvider.qualification}</td>
  //           </tr>
  //         </tbody>
  //       </table>
  //     </div>
  //   </>
  // ) : null;

  return (
    <>
      <ProfileDetails
        /*cardDetails={paymentMethod?.[0]?.attributes}*/ refreshParent={refresh}
      >
        <>
          {dataLoaded == false ? (
            <Card.Body>
              <div className="text-center p-4 mt-4">
                <Spinner
                  style={{ width: 100, height: 100 }}
                  animation="border"
                  variant="info"
                />
              </div>
            </Card.Body>
          ) : null}

          {dataLoaded == true && (
            <div className={`${!dataLoaded ? "invisible" : ""}`}>
              {profileForm}
              {/*{serviceProviderDetails}*/}
              {showConfirmUpdateModal ? (
                <EditProfileModal
                  show={true}
                  onClick={updateProfile}
                  //onClick={() => { }}
                  loading={savingProfile}
                  onHide={onCloseModal}
                />
              ) : null}
            </div>
          )}
        </>
      </ProfileDetails>
      <PaymentMethods userType={"provider"} userId={null}/>
    </>
  );
};

export default Profile;
