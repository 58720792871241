import { yupResolver } from "@hookform/resolvers/yup";
import FeatherIcons from "feather-icons-react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import * as yup from "yup";

import { FormInput, InputMasks, VerticalForm } from "@/apps/common/components/";

import { AxiosError } from "axios";
import { ReactNode, useEffect, useState } from "react";
import {
  DATE_FORMATS,
  formatDateOfBirth,
  getUserTimezone,
  isValidDateFormat,
  reverseFormatDateOfBirth
} from "../../helpers/date";
import { useTitle } from "../../helpers/useTitle";
import { useAuthStore } from "../../store/useAuthStore";
import AuthLayout from "./AuthLayout";

interface UserData {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  phoneNumber: string;
  email: string;
  state: string;
  spokenLanguage: string;
  serviceType: string;
  serviceProvider: string;
  referringDoctor: string;
}

interface ReferralVerificationData {
  lastName: string;
  dateOfBirth: string;
}
interface UserAuthData {
  password: string;
}

/* bottom links */
const BottomLink = () => {
  const { t } = useTranslation();

  return (
    <Row className="mt-3">
      <Col xs={12} className="text-center">
        <p className="text-muted">
          {t("Already have account?")}{" "}
          <Link to={"/auth/login"} className="text-primary fw-bold ms-1">
            {t("Login")}
          </Link>
        </p>
      </Col>
    </Row>
  );
};

const ReferralRegister = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const token = searchParams.get("token");
  const serviceType = searchParams.get("serviceType");

  const senderName =
    searchParams.get("clinic") ?? searchParams.get("senderName");
  const senderPhoneNumber =
    searchParams.get("clinicPhoneNumber") ??
    searchParams.get("senderPhoneNumber");

  const { t } = useTranslation();
  const { validateReferral, register, user, loggedIn } = useAuthStore();
  const [authStep, setAuthStep] = useState(1);
  const [userData, setUserData] = useState<UserData>({
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    phoneNumber: "",
    email: "",
    state: "",
    spokenLanguage: "",
    serviceType: "",
    serviceProvider: "",
    referringDoctor: ""
  });

  const [error, setError] = useState<string | ReactNode>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useTitle("Register");

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 100);
  }, [authStep]);

  const handleCancel = () => {
    navigate("/");
  };
  const onSubmitAuth = async (formData: UserAuthData) => {
    try {
      setLoading(true);
      await register({
        payload: {
          username: userData.email,
          email: userData.email,
          password: formData.password,
          token: token || undefined,
          lastName: userData.lastName,
          dateOfBirth: reverseFormatDateOfBirth(userData.dateOfBirth)!,
          timezone: getUserTimezone()
        }
      });
      setLoading(false);
    } catch (err) {
      const errResponse = (
        err as { response?: { data?: { error?: { message?: string } } } }
      ).response;
      let errMessage = errResponse?.data?.error?.message || "";
      if (errMessage === "Email or Username are already taken") {
        errMessage = t("This email account already exists.");
      }
      setError(errMessage);
      console.log("err", err);
      setLoading(false);
    }
  };

  const referralVerificationSchemaResolver = yupResolver(
    yup.object().shape({
      dateOfBirth: yup
        .string()
        .required(t("Please enter Date of birth"))
        .test("is-valid-date", "Please enter date in MM/DD/YYYY", (value) => {
          return isValidDateFormat(value, DATE_FORMATS.DATE_ONLY);
        }),
      lastName: yup.string().required(t("Please enter Last name"))
    })
  );
  const authSchemaResolver = yupResolver(
    yup.object().shape({
      password: yup.string().required(t("Please enter Password")),
      checkBoxSignup: yup.bool().oneOf([true])
    })
  );

  const onSubmit = () => {
    setAuthStep(3);
  };

  const handleReferralVerification = async (
    formData: ReferralVerificationData
  ) => {
    try {
      setError(null);
      setLoading(true);

      console.log ('handleReferralVerification ' + JSON.stringify(formData))

      const { data } = await validateReferral({
        payload: {
          lastName: formData.lastName,
          dateOfBirth: reverseFormatDateOfBirth(formData.dateOfBirth)!,
          token: token || undefined
        }
      });

      console.debug ('Language - ' + data.spokenLanguage)
      if (data.spokenLanguage === "es")
          data.spokenLanguage = "Spanish"
      else if ((data.spokenLanguage === "english") || (data.spokenLanguage === "en"))
          data.spokenLanguage = "English"

      setUserData({
        firstName: data.firstName,
        lastName: data.lastName,
        dateOfBirth: formatDateOfBirth(data.dateOfBirth),
        phoneNumber: data.phoneNumber,
        email: data.email,
        state: data.state.name,
        spokenLanguage: data.spokenLanguage,
        serviceType: data.serviceType.name,
        serviceProvider: data.serviceProviderType,
        referringDoctor: data.referringDoctor
      });

      setAuthStep(2);
      setLoading(false);
    } catch (err) {
      let errorMessage: string | ReactNode =
        `Sorry, Date of birth and last name does not match the referral. Please contact ${senderName} at ${senderPhoneNumber} if you think this is an error.`;

      if (
        err instanceof AxiosError &&
        err.response?.data.error.message === "TOKEN_EXPIRED"
      ) {
        errorMessage = (
          <span>
            You already have an account setup. Please use your login credentials
            to <Link to={"/auth/login"}>log in</Link>.
          </span>
        );
      }

      setError(errorMessage);
      setLoading(false);
    }
  };

  const redirectUrl = location?.search?.slice(6) || "/";

  return (
    <>
      {loggedIn || user ? <Navigate to={redirectUrl}></Navigate> : null}

      <AuthLayout bottomLinks={<BottomLink />}>
        <h4 className="h4 mb-0 mt-2">{t("Create your account")}</h4>
        {authStep === 1 && (
          <p className="mt-1 mb-2">
            {t(
              `A referral has been sent by ${senderName} for Skyler Health to conduct a ${serviceType} for you. Please verify if you are the correct recipient.`
            )}
          </p>
        )}
        {authStep === 2 && (
          <p className="mt-1 mb-2">
            {t(
              `Please verify and accept the information displayed below from the referral sent by ${senderName} to Skyler Health`
            )}
          </p>
        )}

        {error && (
          <Alert variant="danger" className="my-2">
            {error}
          </Alert>
        )}
        {authStep === 1 && (
          <VerticalForm<ReferralVerificationData>
            onSubmit={handleReferralVerification}
            resolver={referralVerificationSchemaResolver}
            defaultValues={{}}
            formClass="authentication-form row"
          >
            <InputMasks
              type="mask"
              startIcon={
                <FeatherIcons icon={"calendar"} className="icon-dual" />
              }
              label={t("Date of birth")}
              mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
              placeholder="MM/DD/YYYY"
              name="dateOfBirth"
              containerClass={"mb-2 col-12"}
            />
            <FormInput
              label={t("Last name")}
              type="text"
              name="lastName"
              startIcon={<FeatherIcons icon={"user"} className="icon-dual" />}
              placeholder={t("Your last name")}
              containerClass={"mb-3 col-12"}
            />
            <div className="mb-2 text-center d-grid col-12">
              <Button type="submit" disabled={loading}>
                {loading ? t("Loading...") : t("Submit")}
              </Button>
            </div>
          </VerticalForm>
        )}

        {authStep === 2 && (
          <VerticalForm
            onSubmit={onSubmit}
            defaultValues={userData}
            formClass="authentication-form referral-info-form row"
          >
            <FormInput
              label={t("First name")}
              type="text"
              name="firstName"
              placeholder={t("Your first name")}
              containerClass={"mb-2 col-sm-12 col-md-12 col-lg-6"}
              disabled
            />
            <FormInput
              label={t("Last name")}
              type="text"
              name="lastName"
              placeholder={t("Your last name")}
              containerClass={"mb-2 col-sm-12 col-md-12 col-lg-6"}
              disabled
            />
            <FormInput
              label={t("Date of birth")}
              type="text"
              name="dateOfBirth"
              containerClass={"mb-2 col-sm-12 col-md-12 col-lg-6"}
              disabled
            />
            <FormInput
              label={t("Mobile Phone")}
              type="text"
              name="phoneNumber"
              containerClass={"mb-2 col-sm-12 col-md-12 col-lg-6"}
              disabled
            />
            <FormInput
              label={t("Referring Doctor")}
              type="text"
              name="referringDoctor"
              containerClass={"mb-2 col-sm-12 col-md-12 col-lg-6"}
              disabled
            />
            <FormInput
              label={t("Service Type")}
              type="text"
              name="serviceType"
              containerClass={"mb-2 col-sm-12 col-md-12 col-lg-6"}
              disabled
            />
            <FormInput
              label={"Service provider"}
              type="select"
              name="serviceProvider"
              key="serviceProvider"
              containerClass={"mb-2 col-sm-12 col-md-12 col-lg-6"}
              disabled
            >
              <option value="">Select service provider</option>
              <option value="licensed-therapist">Licensed Therapist</option>
              <option value="psychologist">Psychologist</option>
            </FormInput>
            <FormInput
              label={t("State")}
              type="text"
              name="state"
              containerClass={"mb-2 col-sm-12 col-md-12 col-lg-6"}
              disabled
            />
            <FormInput
              label={t("Language Spoken")}
              type="text"
              name="spokenLanguage"
              containerClass={"mb-3 col-sm-12 col-md-12 col-lg-6"}
              disabled
            />

            <div className="mb-2 text-center d-grid col-12">
              <Button type="submit">{t("Accept")}</Button>
            </div>
            <div className="mb-2 text-center d-grid col-12">
              <Button onClick={handleCancel} variant="secondary">
                Cancel
              </Button>
            </div>
          </VerticalForm>
        )}
        {authStep === 3 && (
          <VerticalForm
            onSubmit={onSubmitAuth}
            resolver={authSchemaResolver}
            defaultValues={{}}
            formClass="authentication-form row"
          >
            <FormInput
              label={t("Email Address")}
              type="email"
              name="email"
              value={userData.email}
              startIcon={<FeatherIcons icon={"mail"} className="icon-dual" />}
              placeholder={t("hello@domain.com")}
              containerClass={"mt-2 mb-2 col-12"}
              disabled
            />
            <FormInput
              label={t("Password")}
              type="password"
              name="password"
              startIcon={<FeatherIcons icon={"lock"} className="icon-dual" />}
              placeholder={t("Enter your Password")}
              containerClass={"mb-3 col-12"}
            />
            <FormInput
              element={
                <span className="fs-10">
                  Please check this box to acknowledge that you have read and
                  accept the{" "}
                  <a href="https://i.skylerhealth.com/privacy" target="_blank">
                    Privacy Policy
                  </a>{" "}
                  and the{" "}
                  <a href="https://i.skylerhealth.com/terms" target="_blank">
                    Terms of Use
                  </a>
                </span>
              }
              type="checkbox"
              name="checkBoxSignup"
              containerClass={"mb-2 col-12"}
            />

            <div className="mb-2 text-center d-grid col-12">
              <Button type="submit" disabled={loading}>
                {loading ? t("Loading...") : t("Submit")}
              </Button>
            </div>
          </VerticalForm>
        )}
      </AuthLayout>
    </>
  );
};

export default ReferralRegister;
