import { Route } from "react-router-dom";

// components
import PrivateRoute from "@/apps/common/routes/PrivateRoute";
//import ClinicLayout from "../components/Layout";
import Login from "@/apps/common/pages/auth/Login";
import Logout from "@/apps/common/pages/auth/Logout";

//import BulkEmails from "../pages/BulkEmails/BulkEmails";
import MemberService from "../pages/MemberService/MemberService";
import MemberServiceDetails from "../pages/MemberService/MemberServiceDetails";
import ScheduleNewSession from "../pages/NewSession/ScheduleNewSession"
import ServiceProviders from "../pages/ServiceProviders/serviceProviders";
import Members from "../pages/Members/members";
import { RouteType } from "@/apps/common/components/RoutesHandler";

// dashboards
const dashboardRoutes: RouteType[] = [
  // {
  //   path: "/bulkemails",
  //   name: "BulkEmails",
  //   element: <BulkEmails />,
  //   route: PrivateRoute
  // },
  {
    path: "/memberservice",
    name: "Member Services",
    element: <MemberService />,
    route: PrivateRoute
  },
  {
    path: "/newsession/:id",
    name: "Schedule New Session",
    element: <ScheduleNewSession />,
    route: PrivateRoute
  },
  {
    path: "/memberServiceDetails/:id",
    name: "Member Service Setails",
    element: <MemberServiceDetails/>,
    route: PrivateRoute
  },
  {
    path: "/serviceProviders",
    name: "Service Providers",
    element: <ServiceProviders/>,
    route: PrivateRoute
  },
  {
    path: "/members",
    name: "Members",
    element: <Members/>,
    route: PrivateRoute
  },


  

];

// auth
const authRoutes: RouteType[] = [
  {
    path: "/auth/login",
    name: "Login",
    element: <Login />,
    route: Route
  },
  {
    path: "/auth/logout",
    name: "Logout",
    element: <Logout />,
    route: Route
  }
];

export const defaultRoute = "/memberservice";

export const authProtectedRoutes = [...dashboardRoutes];
export const publicRoutes = [...authRoutes];

export { menuItems } from "./menuItems";
