import { PAYMENT_TYPE_DISPLAY } from "@/apps/common/constants";
import { getFullName } from "@/apps/common/helpers";
import {
  DATE_FORMATS,
  formatDate,
  formatDateOfBirth
} from "@/apps/common/helpers/date";
import { ReferralType } from "@/apps/common/types/appointment-types";
import config from "@/config";
import FeatherIcon from "feather-icons-react";
import { Button, Card } from "react-bootstrap";
import { PortalTypes } from "@/constants/portals";
import { getPortalType } from "@/apps/common/helpers";

export default function ReferralDetails({
  referralDetail
}: {
  referralDetail: ReferralType;
}) {
  if (!referralDetail) {
    return;
  }

  const providerReport = referralDetail.memberService?.providerReport;

  let reportLink =
    providerReport?.status === "complete"
      ? providerReport?.document?.url
      : null;

  if (reportLink && !reportLink.startsWith("http")) {
    reportLink = config.API_URL + reportLink;
  }

  const memberService = referralDetail?.memberService;
  const appointment = memberService?.appointment;
  const timeSlot = appointment?.timeSlot;
  // const serviceProvider = timeSlot?.serviceProvider;
  const service = referralDetail?.serviceType;
  const status = memberService?.status;
  const paymentLogs = memberService?.paymentLogs;

  return (
    <Card>
      <Card.Body>
        <div className="text-center mt-2">
          <h4 className="mv-2">{getFullName(referralDetail)}</h4>
        </div>
        <table className="table table-borderless mb-0 text-muted timeline-referral-details">
          <tbody>
            <tr>
              <th scope="row">Referred on</th>
              <td>
                {formatDate(referralDetail.createdAt, DATE_FORMATS.DATE_ONLY)}
              </td>
            </tr>
            <tr>
              <th scope="row">Service</th>
              <td>{service?.name}</td>
            </tr>
            <tr>
              <th scope="row">Status</th>
              <td>
                <span
                  className={`services-status ${status || "referral-sent"}`}
                >
                  {status || "referral-sent"}
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        {reportLink && (getPortalType() !== PortalTypes.PARTNER) && (
          <div className="mt-3 pt-2 border-top">
            <h4 className="mb-2 fs-15">Report</h4>
            <Button
              onClick={() => window.open(reportLink!)}
              className="btn btn-xs btn-primary rounded-pill"
            >
              <div className="d-flex align-items-center">
                <FeatherIcon icon="download" />
                <span className="mx-1">Download report</span>
              </div>
            </Button>
          </div>
        )}

        {paymentLogs?.length ? (
          <div className="mt-3 pt-2 border-top">
            <h4 className="mb-2 fs-15">Payment Details</h4>
            <table className="table table-borderless mb-0 text-muted">
              <tbody>
                {paymentLogs.map((paymentLog) => (
                  <tr key={paymentLog.id}>
                    <th scope="row">Date</th>
                    <td>
                      {paymentLog.status}
                      <br />
                      {formatDate(paymentLog.updatedAt)}
                      <br />
                      {Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD"
                      }).format(paymentLog.amount / 100)}
                      <br />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}

        {appointment && (
          <div className="mt-3 pt-2 border-top">
            <h4 className="mb-2 fs-15">Appointment Details</h4>
            <table className="table table-borderless mb-0 text-muted">
              <tbody>
                <tr>
                  <th scope="row">Date</th>
                  <td>{formatDate(timeSlot!.startDate)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        <div className="mt-3 pt-2 border-top">
          <h4 className="mb-2 fs-15">Referral Details</h4>
          <div className="table-responsive">
            <table className="table table-borderless mb-0 text-muted">
              <tbody>
                <tr>
                  <th scope="row">DOB</th>
                  <td>{formatDateOfBirth(referralDetail.dateOfBirth)}</td>
                </tr>
                <tr>
                  <th scope="row">Email</th>
                  <td>{referralDetail.email}</td>
                </tr>
                <tr>
                  <th scope="row">Phone</th>
                  <td>{referralDetail.phoneNumber}</td>
                </tr>
                <tr>
                  <th scope="row">State</th>
                  <td>{referralDetail.state?.name}</td>
                </tr>
                {referralDetail.referringDoctor ? (
                  <tr>
                    <th scope="row">Referring Doctor</th>
                    <td>{referralDetail.referringDoctor}</td>
                  </tr>
                ) : null}
                <tr>
                  <th scope="row">Payment By</th>
                  <td>{PAYMENT_TYPE_DISPLAY[referralDetail.paymentType]}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}
