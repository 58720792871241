import { api } from "@/apps/common/api-client";
import { unstrapiMany } from "@/apps/common/helpers/strapi";
import { useRequest } from "ahooks";
import FeatherIcon from "feather-icons-react";
import Select from "react-select";
import { QualificationType } from "../../../common/types/appointment-types";

export default function QualificationSelector({
  value,
  onChange
}: {
  name: string;
  value: QualificationType[];
  onChange: (value: QualificationType[]) => void;
}) {
  const { data: qualifications = [] } = useRequest(
    () =>
      api.auth
        .fetchQualifications()
        .then((response) => unstrapiMany<QualificationType>(response.data, 'qualification')),
    {
      cacheKey: "qualifications"
    }
  );

  //console.debug ('qualifications - ' + JSON.stringify(qualifications))
  return (
    <div className="position-relative input-group">
      <span className="input-group-text">
        <FeatherIcon icon="award" />
      </span>

      <Select
        isMulti
        options={qualifications}
        onChange={(qualifications) => onChange(qualifications as QualificationType[])}
        defaultValue={value}
        getOptionLabel={(option) => option.description}
        getOptionValue={(option) => String(option.id)}
        styles={{
          control: (base: any) => ({
            ...base,
            borderWidth: 0
          })
        }}
        className="form-control"
      />
    </div>
  );
}
