export const menuItems = [
  // {
  //   key: "email",
  //   icon: "email",
  //   label: "Bulk Emails",
  //   isTitle: true,
  //   url: "/bulkemails"
  // },
  {
    key: "memberServices",
    icon: "file-text",
    label: "Member Services",
    isTitle: true,
    url: "/memberservice",
  },
  {
    key: "serviceProviders",
    icon: "file-text",
    label: "Service Providers",
    isTitle: true,
    url: "/serviceProviders",
  },
  // {
  //   key: "members",
  //   icon: "file-text",
  //   label: "Members",
  //   isTitle: true,
  //   url: "/members",
  // },
];
