export const survey_translation =
  [
  {
    "Psychological Evaluation Summary": "Resumen de la Evaluación Psicológica"
  },
  {
    "Thank You": "Gracias"
  },
  {
    "Thank you for your patience to answer all of the questions. These will be very helpful for the counselor for a personalized session and to make the session highly effective. We will now schedule your session with a Skyler Health counselor. Counselors are available to talk to you now!": "Gracias por tu paciencia para responder todas las preguntas. Estas serán muy útiles para el consejero en una sesión personalizada y para hacer que la sesión sea altamente efectiva. Ahora programaremos tu sesión con un consejero de Skyler Health. ¡Los consejeros están disponibles para hablar contigo ahora!"
  },
  {
    "Psychological Evaluation for Surgical Clearance Introduction": "Evaluación Psicológica para Liberación Quirúrgica Introducción"
  },
  {
    "Welcome to Skyler Health Psychological Evaluation for Surgical Clearance! We are committed to providing you with rapid appointments and counselor sessions as quickly as possible so that you can get back to life! In order to schedule a session with a counselor, please take 15 minutes to answer questions about your current health and medical history. You will then schedule a session with a counselor for a virtual video or phone call appointment. We guarantee that you will be very happy with your session or we will refund your fees. A final comprehensive report by the counselor will be sent to you (or your doctor if requested). Shall we begin?": "¡Bienvenido a la Evaluación Psicológica de Salud Skyler para la Autorización Quirúrgica! ¡Estamos comprometidos en brindarte citas rápidas y sesiones con un consejero lo más rápido posible para que puedas volver a tu vida! Para programar una sesión con un consejero, por favor tómate 15 minutos para responder preguntas sobre tu salud actual y tu historial médico. Luego programarás una sesión con un consejero para una cita virtual por video o llamada telefónica. Garantizamos que quedarás muy satisfecho con tu sesión o te devolveremos tus honorarios. Un informe final y completo elaborado por el consejero te será enviado (o a tu médico si así lo solicitas). ¿Comenzamos?"
  },
  {
    "Mental Health": "Salud mental"
  },
  {
    "Yes": "Sí"
  },
  {
    "No": "No"
  },
  {
    "Have you been diagnosed with schizophrenia?": "¿Has sido diagnosticado con esquizofrenia?"
  },
  {
    "Have you been diagnosed with bipolar disorder?": "¿Has sido diagnosticado/a con trastorno bipolar?"
  },
  {
    "Have you ever been hospitalized for a psychiatric condition?": "¿Alguna vez has sido hospitalizado por una condición psiquiátrica?"
  },
  {
    "Have you ever attended therapy for a mental health condition?": "¿Alguna vez has recibido terapia para una condición de salud mental?"
  },
  {
    "Have you ever taken medication for a mental health condition?": "¿Alguna vez has tomado medicación para una condición de salud mental?"
  },
  {
    "Are you currently experiencing suicidal or homicidal ideation ?": "¿Estás experimentando actualmente ideas suicidas o homicidas?"
  },
  {
    "Do you have a history of suicidal or homicidal ideation or attempts": "¿Tienes antecedentes de ideación suicida o homicida o intentos?"
  },
  {
    "Have you ever been diagnosed with other neurological issues not mentioned above?": "¿Alguna vez te han diagnosticado otros problemas neurológicos que no se mencionaron anteriormente?"
  },
  {
    "Migraines": "Migrañas"
  },
  {
    "Seizures": "Convulsiones"
  },
  {
    "Stroke": "Accidente cerebrovascular"
  },
  {
    "Traumatic Brain Injury": "Lesión cerebral traumática"
  },
  {
    "Dementia": "Demencia"
  },
  {
    "None of the above": "Ninguna de las opciones anteriores"
  },
  {
    "Have you ever diagnosed with or experienced any of the following neurological conditions?": "¿Alguna vez has sido diagnosticado o has experimentado alguna de las siguientes condiciones neurológicas?"
  },
  {
    "Do you have auditory hallucinations such as hearing intrusive sounds or other voices besides your own internally and/or externally that others cannot hear?": "¿Tienes alucinaciones auditivas como escuchar sonidos intrusivos u otras voces que no son las tuyas interna y/o externamente que los demás no pueden escuchar?"
  },
  {
    "Please answer these questions about some aspects of your mental health. We are interested in the types of mental health conditions that you are diagnosed with or experiencing now or have experienced in the past. These statements below describe mental health conditions. Using the options, please indicate Yes / No.": "Por favor, responde estas preguntas sobre algunos aspectos de tu salud mental. Estamos interesados en los tipos de trastornos de salud mental con los que has sido diagnosticado o estás experimentando ahora o has experimentado en el pasado. Las afirmaciones a continuación describen condiciones de salud mental. Utilizando las opciones, por favor indica Sí / No."
  },
  {
    "Please explain what are you experiencing ?": "Por favor, explique qué está experimentando."
  },
  {
    "Please explain your past experience.": "Por favor, explique su experiencia pasada."
  },
  {
    "Please list the neurological issues that you have been diagnosed with:": "Por favor, enumere los problemas neurológicos con los que ha sido diagnosticado:"
  },
  {
    "Medical History": "Historia clínica"
  },
  {
    "Do you have any problems with vision ?": "¿Tienes algún problema con la visión?"
  },
  {
    "Do you have any problems with hearing ?": "¿Tienes algún problema con la audición?"
  },
  {
    "Have you ever been diagnosed with any medical conditions ?": "¿Alguna vez te han diagnosticado alguna condición médica?"
  },
  {
    "Have you had any major hospitalization and/or surgical procedures?": "¿Has tenido alguna hospitalización importante y/o procedimientos quirúrgicos?"
  },
  {
    "Are you currently on prescribed medications? Please list the medications and dosages in the text area that will open up when you answer Yes.": "¿Estás actualmente tomando medicamentos recetados? Por favor, enumera los medicamentos y dosis en el área de texto que se abrirá cuando respondas Sí."
  },
  {
    "Please answer these questions about your medical history. We are interested in your medications and medical conditions.": "Por favor responda estas preguntas sobre su historial médico. Estamos interesados en sus medicamentos y condiciones médicas."
  },
  {
    "Please explain what types of vision issues are you having ?": "¿Por favor, puedes explicar qué tipo de problemas de visión tienes?"
  },
  {
    "Please explain what types of hearing issues are you having ?": "Por favor, explique qué tipos de problemas de audición tiene."
  },
  {
    "List the Medical Conditions": "Enumerar las condiciones médicas"
  },
  {
    "What types of surgeries and/or hospitalizations ?": "¿Qué tipos de cirugías y/o hospitalizaciones?"
  },
  {
    "List all medications and dosages taken ?": "¿Cuáles son todos los medicamentos y dosis que toma?"
  },
  {
    "Pain Management": "Gestión del Dolor"
  },
  {
    "Are you living with on-going chronic pain ?": "¿Estás viviendo con dolor crónico continuo?"
  },
  {
    "Are you taking this psychological evaluation for an upcoming surgery or procedure ?": "¿Estás tomando esta evaluación psicológica para una cirugía o procedimiento próximo?"
  },
  {
    "Please answer these questions about your ability to manage chronic pain or pain after surgery. We are interested in learning about your pain management and coping skills so that we can make appropriate recommendations to you. Questions below are necessary for this evaluation.": "Por favor responda a estas preguntas sobre su capacidad para manejar el dolor crónico o el dolor después de una cirugía. Estamos interesados en conocer sus habilidades para el manejo del dolor y para hacer recomendaciones apropiadas. Las preguntas a continuación son necesarias para esta evaluación."
  },
  {
    "Describe what exercises you do": "Describe qué ejercicios haces."
  },
  {
    "Describe how it impacts your sleep": "Describe cómo afecta a tu sueño"
  },
  {
    "Describe what other factors impact your sleep": "Describe qué otros factores afectan tu sueño."
  },
  {
    "Are you able to exercise ?": "¿Puedes hacer ejercicio?"
  },
  {
    "Lower Back": "Espalda baja"
  },
  {
    "Mid Back": "Espalda media"
  },
  {
    "Upper Back": "Espalda superior"
  },
  {
    "Hip": "Cadera"
  },
  {
    "Knees": "Rodillas"
  },
  {
    "Shoulders": "Hombros"
  },
  {
    "Arms": "Brazos"
  },
  {
    "Legs": "Piernas"
  },
  {
    "Abdomen": "Abdomen."
  },
  {
    "Head": "Cabeza"
  },
  {
    "Where is your pain located ?": "¿Dónde está localizado tu dolor?"
  },
  {
    "Surgery": "Cirugía"
  },
  {
    "Physical Therapy": "Terapia física"
  },
  {
    "Epidural Injections": "Inyecciones epidurales"
  },
  {
    "Stimulator Neuromodulation": "Estimulador de Neuromodulación"
  },
  {
    "Opioid Therapy": "Terapia con opioides"
  },
  {
    "Chiropractic Care": "Atención quiropráctica"
  },
  {
    "Acupuncture": "Acupuntura"
  },
  {
    "Other": "\"Otros\""
  },
  {
    "Which interventions helped ?": "¿Cuáles intervenciones ayudaron?"
  },
  {
    "Burning": "Quemando"
  },
  {
    "Sharp": "Agudo"
  },
  {
    "Tingling": "Hormigueo"
  },
  {
    "Searing": "Sear significa \"sellado\" o \"sellador\" en español."
  },
  {
    "Dull": "Aburrido"
  },
  {
    "Intense": "Intenso"
  },
  {
    "Aching": "Doloroso."
  },
  {
    "Cramping": "Calambre"
  },
  {
    "Shooting": "Disparar"
  },
  {
    "Stabbing": "Apuñalamiento"
  },
  {
    "How would you describe the pain ?": "¿Cómo describirías el dolor?"
  },
  {
    "Does your pain impact your sleep ?": "¿Tu dolor afecta tu sueño?"
  },
  {
    "What interventions have you tried ?": "¿Qué intervenciones has intentado?"
  },
  {
    "What is your least pain level 1-10 ?": "¿Cuál es tu nivel de dolor mínimo del 1 al 10?"
  },
  {
    "What is your worst pain level 1-10 ?": "¿Cuál es tu nivel de dolor más intenso del 1 al 10?"
  },
  {
    "Any other factors impact your sleep ?": "¿Otros factores afectan tu sueño?"
  },
  {
    "What is your average pain level 1-10 ?": "¿Cuál es tu nivel de dolor promedio de 1 a 10?"
  },
  {
    "What is your current pain level 1-10 ?": "¿Cuál es tu nivel de dolor actual del 1 al 10?"
  },
  {
    "Does your pain or anything else impact your appetite ?": "¿Tu dolor o cualquier otra cosa afecta tu apetito?"
  },
  {
    "less than 1 year": "menos de 1 año"
  },
  {
    "more than 10 years": "más de 10 años"
  },
  {
    "How many years have you had pain in this/these area(s)?": "¿Hace cuántos años has tenido dolor en esta(s) área(s)?"
  },
  {
    "less than 1 hour": "Menos de 1 hora."
  },
  {
    "over 12 hours": "más de 12 horas"
  },
  {
    "": "I'm sorry, but you haven't provided any text for me to translate. Please provide the text you would like me to translate into Spanish."
  },
  {
    "How many hours of sleep do you get every night on average ?": "¿Cuántas horas de sueño duermes en promedio todas las noches?"
  },
  {
    "Are you open to pain coaching with a licensed counselor to help you with your chronic pain ?": "¿Estás abierto/a a un entrenamiento de manejo del dolor con un consejero/a licenciado/a para ayudarte con tu dolor crónico?"
  },
  {
    "Describe what other factors impact your appetite": "Describe qué otros factores afectan tu apetito"
  },
  {
    "Has your physician explained the risk and benefits of this procedure?": "¿Ha explicado su médico los riesgos y beneficios de este procedimiento?"
  },
  {
    "Do you agree to read the brochure given by the device company if not done already?": "¿Estás de acuerdo en leer el folleto proporcionado por la empresa del dispositivo si aún no lo has hecho?"
  },
  {
    "Are you open to pain coaching with a licensed counselor to help you with your pain after surgery ?": "¿Estás dispuesto/a a recibir orientación sobre el dolor con un consejero con licencia para ayudarte con tu dolor después de la cirugía?"
  },
  {
    "Are you able to handle any psychological (anxiety, depression, stress) and physical discomfort following surgery ?": "¿Puedes manejar cualquier malestar psicológico (ansiedad, depresión, estrés) y físico después de la cirugía?"
  },
  {
    "Do you understand the risks of this procedure (i.e. not all have successful outcomes and some may have associated complications?": "¿Comprendes los riesgos de este procedimiento (es decir, que no todos tienen resultados exitosos y algunos pueden tener complicaciones asociadas)?"
  },
  {
    "Do you have appropriate expectations related to the procedure including understanding that it may not reduce your pain to the percentage you are hoping for?": "¿Tienes expectativas apropiadas respecto al procedimiento, incluyendo la comprensión de que puede que no reduzca tu dolor en el porcentaje que esperas?"
  },
  {
    "Anxiety": "Ansiedad"
  },
  {
    "Not at all": "Para nada"
  },
  {
    "Several days": "Varios días"
  },
  {
    "More than half the days": "Más de la mitad de los días"
  },
  {
    "Nearly every day": "Casi todos los días"
  },
  {
    "Over the last two weeks, how often have you been bothered by trouble relaxing?": "Durante las últimas dos semanas, ¿con qué frecuencia te has sentido molesto/a por dificultad para relajarte?"
  },
  {
    "Over the last two weeks, how often have you been bothered by becoming easily annoyed or irritable?": "Durante las últimas dos semanas, ¿con qué frecuencia te has sentido molesto o irritable con facilidad?"
  },
  {
    "Over the last two weeks, how often have you been bothered by feeling nervous, anxious, or on edge?": "Durante las últimas dos semanas, ¿con qué frecuencia te has sentido nervioso, ansioso o inquieto?"
  },
  {
    "Over the last two weeks, how often have you been bothered by worrying too much about different things?": "Durante las últimas dos semanas, ¿con qué frecuencia te ha preocupado demasiado por diferentes cosas?"
  },
  {
    "Over the last two weeks, how often have you been bothered by not being able to stop or control worrying?": "Durante las últimas dos semanas, ¿con qué frecuencia te has sentido preocupado por no poder detener o controlar tus pensamientos preocupantes?"
  },
  {
    "Over the last two weeks, how often have you been bothered by being so restless that it is hard to sit still?": "Durante las últimas dos semanas, ¿con qué frecuencia te ha molestado estar tan inquieto que te resulte difícil estar quieto?"
  },
  {
    "Over the last two weeks, how often have you been bothered by feeling afraid, as if something awful might happen?": "Durante las últimas dos semanas, ¿con qué frecuencia te has sentido preocupado por sentir miedo, como si algo terrible pudiera suceder?"
  },
  {
    "Please answer these questions that pertain to your feelings of anxiety over the last two weeks. These questions are meant to assess if you are experiencing any anxiety. The statements below describe different feelings of anxiety. Using the options, please indicate the degree to which you are experiencing these thoughts and feelings.": "Por favor responda a estas preguntas que se refieren a sus sentimientos de ansiedad en las últimas dos semanas. Estas preguntas están diseñadas para evaluar si está experimentando alguna ansiedad. Las siguientes afirmaciones describen diferentes sentimientos de ansiedad. Utilizando las opciones, por favor indique el grado en el que está experimentando estos pensamientos y sentimientos."
  },
  {
    "Depression": "Depresión"
  },
  {
    "Over the last two weeks, how often have you been bothered by poor appetite or overeating?": "Durante las últimas dos semanas, ¿con qué frecuencia te has sentido molestado/a por la falta de apetito o por comer en exceso?"
  },
  {
    "Over the last two weeks, how often have you been bothered by feeling down, depressed, or hopeless?": "¿Durante las últimas dos semanas, con qué frecuencia te has sentido bajo de ánimo, deprimido o sin esperanza?"
  },
  {
    "Over the last two weeks, how often have you been bothered by feeling tired or having little energy?": "Durante las últimas dos semanas, ¿con qué frecuencia te has sentido molesto/a por estar cansado/a o tener poca energía?"
  },
  {
    "Over the last two weeks, how often have you been bothered by little interest or pleasure in doing things?": "Durante las últimas dos semanas, ¿con qué frecuencia te has sentido molesto por tener poco interés o placer en hacer cosas?"
  },
  {
    "Over the last two weeks, how often have you been bothered by trouble falling or staying asleep, or sleeping too much?": "Durante las últimas dos semanas, ¿con qué frecuencia has tenido problemas para conciliar el sueño, permanecer dormido o dormir demasiado?"
  },
  {
    "Over the last two weeks, how often have you been bothered by thoughts that you would be better off dead or of hurting yourself in some way?": "Durante las últimas dos semanas, ¿con qué frecuencia has sido molestado por pensamientos de que sería mejor estar muerto o de hacerte daño de alguna manera?"
  },
  {
    "Over the last two weeks, how often have you been bothered by trouble concentrating on things, such as reading the newspaper or watching television?": "Durante las últimas dos semanas, ¿con qué frecuencia te ha molestado tener problemas para concentrarte en cosas como leer el periódico o ver la televisión?"
  },
  {
    "Over the last two weeks, how often have you been bothered by feeling bad about yourself - or that you are a failure or have let yourself or your family down?": "Durante las últimas dos semanas, ¿con qué frecuencia te has sentido mal contigo mismo - o como un fracasado o como si te hubieras decepcionado a ti mismo o a tu familia?"
  },
  {
    "Over the last two weeks, how often have you been bothered by moving or speaking so slowly that other people could have noticed? Or the opposite - being so fidgety or restless that you have been moving around a lot more than usual?": "Durante las últimas dos semanas, ¿con qué frecuencia te ha molestado moverte o hablar tan lentamente que otras personas podrían haberlo notado? ¿O, por el contrario, has estado tan inquieto o inquieto que te has estado moviendo mucho más de lo habitual?"
  },
  {
    "Please answer these questions that pertain to your feelings of depression over the last two weeks. These questions are meant to assess if you are experiencing any mood and depression related challenges. The statements below describe different feelings of mood. Using the options, please indicate the degree to which you are experiencing these thoughts and feelings.": "Por favor responda a estas preguntas que se refieren a sus sentimientos de depresión durante las últimas dos semanas. Estas preguntas están destinadas a evaluar si está experimentando algún desafío relacionado con su estado de ánimo y la depresión. Las declaraciones a continuación describen diferentes sentimientos de ánimo. Utilizando las opciones, indique el grado en el que está experimentando estos pensamientos y sentimientos."
  },
  {
    "Coping Skills and Social Support": "Habilidades para afrontar situaciones y apoyo social"
  },
  {
    "Have you ever noticed your pain is more likely to decrease when you are around loved ones ?": "¿Alguna vez has notado que tu dolor tiende a disminuir más cuando estás cerca de tus seres queridos?"
  },
  {
    "Do you have a network (at least 2-3) people that can provide you with practical or emotional support ?": "¿Tienes una red (de al menos 2-3) personas que pueden brindarte apoyo práctico o emocional?"
  },
  {
    "Are there any other adaptive coping skills not present in the list above that you would like to mention ?": "¿Hay alguna otra habilidad de afrontamiento adaptativo que no esté presente en la lista anterior y te gustaría mencionar?"
  },
  {
    "Physical Activity (indoors or outdoors)": "Actividad física (dentro o fuera)"
  },
  {
    "Spiritual Practice (meditation or prayer)": "Práctica espiritual (meditación o oración)"
  },
  {
    "Creativity (Drawing, writing etc.)": "Creatividad (dibujo, escritura, etc.)"
  },
  {
    "Humor": "Humor\n\nTraducción al español: Humor"
  },
  {
    "Journaling": "Escribir un diario"
  },
  {
    "Acceptance": "Aceptación"
  },
  {
    "Venting with a trusted person": "Desahogarse con una persona de confianza"
  },
  {
    "Healthy Moderate Purposeful Distraction (Reading, watching a movie, or playing a game)": "Distraerse de manera saludable, moderada y con propósito (leyendo, viendo una película o jugando un juego)"
  },
  {
    "Ability to reach out to friends, family, social support when needed": "Capacidad para comunicarse con amigos, familiares, y apoyo social cuando sea necesario"
  },
  {
    "Do you have at least one of the following healthy adaptive coping skills that you practice on a regular basis? Select all those that apply.": "¿Tienes al menos una de las siguientes habilidades saludables de afrontamiento adaptativo que practicas regularmente? Selecciona todas las que apliquen."
  },
  {
    "Please answer these questions about your coping skills and social support. We are interested in learning what coping skills you currently use and what social support you currently have to manage your physical, emotional and mental health. These questions below are necessary for this evaluation.": "Por favor, responde estas preguntas sobre tus habilidades de afrontamiento y apoyo social. Estamos interesados en saber qué habilidades de afrontamiento utilizas actualmente y qué apoyo social tienes para manejar tu salud física, emocional y mental. Estas preguntas son necesarias para esta evaluación."
  },
  {
    "List your close network of people that you rely on, for eg. spouse, children, parents.": "Enumere su red cercana de personas en las que confía, por ejemplo, cónyuge, hijos, padres."
  },
  {
    "List any other skills that you would like to mention.": "Enumere cualquier otra habilidad que le gustaría mencionar."
  },
  {
    "Substance Use": "Uso de sustancias"
  },
  {
    "Have you ever felt guilty about your drinking or drug use?": "¿Alguna vez te has sentido culpable por tu consumo de alcohol o drogas?"
  },
  {
    "Have people annoyed you by criticizing your drinking or drug use?": "¿Te han molestado las personas criticando tu consumo de alcohol o drogas?"
  },
  {
    "Have you ever felt the need to cut down on your drinking or drug use?": "¿Alguna vez has sentido la necesidad de reducir tu consumo de alcohol o drogas?"
  },
  {
    "Have you ever felt you needed a drink or used drugs first thing in the morning to steady your nerves or to get rid of a hangover?": "¿Alguna vez has sentido la necesidad de tomar una bebida o usar drogas apenas te levantas por la mañana para calmar tus nervios o deshacerte de la resaca?"
  },
  {
    "Please answer these questions about any substance use. These statements below describing possible use of substances if any. Using the options Yes / No, please indicate truthfully your use of substances if any. Are you ready to start?": "Por favor responda a estas preguntas sobre el uso de sustancias. Las siguientes afirmaciones describen el posible uso de sustancias, si es que lo hay. Utilizando las opciones Sí / No, indique honestamente su uso de sustancias, si es que lo hay. ¿Está listo para comenzar?"
  },
  {
    "Do you have cravings and urges to use the substance?": "¿Tienes antojos y deseos de utilizar la sustancia?"
  },
  {
    "Do you need more of the substance to get the effect you want?": "¿Necesitas más de la sustancia para obtener el efecto que deseas?"
  },
  {
    "Alcohol": "Alcohol"
  },
  {
    "Cannabis": "Cannabis"
  },
  {
    "Prescription Drugs": "Medicamentos recetados"
  },
  {
    "Which substance(s) do you feel these questions applied to you for?": "¿A qué(s) sustancia(s) sientes que estas preguntas se aplican?"
  },
  {
    "Do you continue to use, even when it causes problems in relationships?": "¿Sigues utilizando, incluso cuando causa problemas en las relaciones?"
  },
  {
    "Do you use substances again and again, even when it puts you in danger?": "¿Utilizas sustancias una y otra vez, incluso cuando te pone en peligro?"
  },
  {
    "Do you want to cut down or stop using the substance but are not managing to?": "¿Quieres reducir o dejar de usar la sustancia pero no estás logrando hacerlo?"
  },
  {
    "Do you take the substance in larger amounts or for longer than you're meant to?": "¿Consumes la sustancia en cantidades mayores o durante más tiempo del que se supone que debes?"
  },
  {
    "Do you have withdrawal symptoms which can be relieved by taking more of the substance?": "¿Tienes síntomas de abstinencia que puedan aliviarse tomando más de la sustancia?"
  },
  {
    "Do you spending a lot of time getting, using, or recovering from use of the substance?": "¿Pasas mucho tiempo consiguiendo, usando o recuperándote del uso de la sustancia?"
  },
  {
    "Do you not manage to do what you should at work, home, or school because of substance use?": "¿No logras hacer lo que debes en el trabajo, casa o escuela debido al uso de sustancias?"
  },
  {
    "Less than 2 years": "Menos de 2 años"
  },
  {
    "Over 5 years": "Más de 5 años."
  },
  {
    "How long have you been in recovery from abusing drugs, alcohol, and/or prescriptions drugs ?": "¿Cuánto tiempo llevas en recuperación de abuso de drogas, alcohol y/o medicamentos recetados?"
  },
  {
    "Do you give up important social, occupational, or recreational activities because of substance use?": "¿Renuncias a actividades sociales, laborales o recreativas importantes debido al uso de sustancias?"
  },
  {
    "Do you continue to use, even when you know you have a physical or psychological problem that could have been caused or made worse by the substance?": "¿Sigues consumiendo, incluso cuando sabes que tienes un problema físico o psicológico que podría haber sido causado o empeorado por la sustancia?"
  },
  {
    "Personal Social History": "Historia Social Personal"
  },
  {
    "Both parents": "Ambos padres."
  },
  {
    "Mother": "Madre"
  },
  {
    "Father": "Padre"
  },
  {
    "Grandparents": "Abuelos."
  },
  {
    "Extended Family Member": "Miembro de la familia extendida"
  },
  {
    "Family Friend or Legal Guardian": "Amigo/a de la familia o tutor legal"
  },
  {
    "Who raised you ?": "¿Quién te crió?"
  },
  {
    "Where were you born ?": "¿Dónde naciste?"
  },
  {
    "Where were you raised ?": "¿Dónde fuiste criado?"
  },
  {
    "How many children do you have ?": "¿Cuántos hijos tienes?"
  },
  {
    "How many siblings do you have ?": "¿Cuántos hermanos tienes?"
  },
  {
    "Healthy": "Saludable"
  },
  {
    "Unhealthy": "No saludable"
  },
  {
    "Traumatic": "Traumático"
  },
  {
    "Normal": "Normal"
  },
  {
    "How would you describe your childhood ?": "¿Cómo describirías tu infancia?"
  },
  {
    "Contract": "Contrato"
  },
  {
    "Part Time": "Tiempo parcial"
  },
  {
    "Full Time": "Tiempo completo"
  },
  {
    "Unemployed": "Desempleado"
  },
  {
    "Disabled or Retired": "Discapacitado o jubilado"
  },
  {
    "What is your current employment status ?": "¿Cuál es tu estado actual de empleo?"
  },
  {
    "None": "Ninguno"
  },
  {
    "Elementary": "Primario / elemental"
  },
  {
    "Middle School": "Escuela secundaria"
  },
  {
    "High School Diploma": "Diploma de escuela secundaria"
  },
  {
    "Some College": "Algunos cursos universitarios"
  },
  {
    "Associates Degree": "Titulo de Asociado"
  },
  {
    "Bachelors Degree": "Licenciatura"
  },
  {
    "Masters Degree or PHD/Doctorate": "Maestría o Doctorado"
  },
  {
    "What is your highest level of education?": "¿Cuál es tu nivel más alto de educación?"
  },
  {
    "Married": "Casado"
  },
  {
    "Single": "Soltero"
  },
  {
    "Long-term relationship": "Relación a largo plazo"
  },
  {
    "New relationship": "Nueva relación"
  },
  {
    "Widowed": "Viudo/a"
  },
  {
    "What is your marital or relationship status ?": "¿Cuál es tu estado civil o situación sentimental?"
  },
  {
    "What have you done for work most of your life ?": "¿Qué has hecho profesionalmente durante la mayor parte de tu vida?"
  },
  {
    "Close": "Cerca"
  },
  {
    "Supportive": "Apoyo"
  },
  {
    "Warm": "Cálido"
  },
  {
    "Cold": "Frío"
  },
  {
    "Distant": "Lejano"
  },
  {
    "Tense": "Tenso"
  },
  {
    "How would you describe your relationships with your family members ?": "¿Cómo describirías tus relaciones con los miembros de tu familia?"
  },
  {
    "How would you describe your relationships with your family members growing up ?": "¿Cómo describirías tus relaciones con los miembros de tu familia mientras crecías?"
  },
  {
    "Please answer these questions about your personal social and family history. We are interested in learning about your personal social and family history to better understand it's psychological impact on your pain and health for a comprehensive assessment. These questions below are necessary for this evaluation.": "Por favor responde a estas preguntas sobre tu historia personal, social y familiar. Estamos interesados en conocer tu historia personal, social y familiar para entender mejor su impacto psicológico en tu dolor y salud para una evaluación integral. Estas preguntas a continuación son necesarias para esta evaluación."
  },
  {
    "Pain Thoughts": "Pensamientos de dolor"
  },
  {
    "To a slight degree": "A un leve grado"
  },
  {
    "To a moderate degree": "A un grado moderado"
  },
  {
    "To a great degree": "Hasta cierto grado"
  },
  {
    "All the time": "Todo el tiempo."
  },
  {
    "I feel I can't go on.": "Me siento que no puedo seguir adelante."
  },
  {
    "I feel I can't stand it anymore.": "Siento que ya no puedo soportarlo más."
  },
  {
    "I anxiously want the pain to go away.": "Anhelo que el dolor desaparezca ansiosamente."
  },
  {
    "I can't seem to keep it out of my mind.": "No puedo dejar de pensar en ello."
  },
  {
    "I keep thinking about how much it hurts.": "Sigo pensando en cuánto duele."
  },
  {
    "I keep thinking of other painful events.": "Sigo pensando en otros eventos dolorosos."
  },
  {
    "It's awful and I feel that it overwhelms me.": "Es terrible y siento que me abruma."
  },
  {
    "I become afraid that the pain will get worse.": "Me da miedo que el dolor empeore."
  },
  {
    "I wonder whether something serious may happen.": "Me pregunto si algo serio puede suceder."
  },
  {
    "I worry all the time about whether the pain will end.": "Me preocupo todo el tiempo de si el dolor terminará."
  },
  {
    "I keep thinking about how badly I want the pain to stop.": "Sigo pensando en lo mucho que deseo que el dolor pare."
  },
  {
    "It's terrible and I think it's never going to get any better.": "Es terrible y creo que nunca va a mejorar."
  },
  {
    "There's nothing I can do to reduce the intensity of the pain.": "No hay nada que pueda hacer para reducir la intensidad del dolor."
  },
  {
    "Please answer these questions about your pain thoughts and feelings over the past 2 weeks. We are interested in the types of thoughts and feeling that you have when you are in pain. Negative pain thoughts are an exaggerated negative orientation toward noxious stimuli and plays an important role in experiencing and coping with pain. This section consists of 13 statements containing a number of thoughts and feelings one may experience when having pain. Using the options, please indicate the degree to which you have these thoughts and feelings when you are experiencing pain. This will help oour counselors to suggest the best course of action to manage your pain.": "Por favor, responda a estas preguntas sobre sus pensamientos y sentimientos sobre el dolor en las últimas 2 semanas. Estamos interesados en los tipos de pensamientos y sentimientos que tiene cuando está experimentando dolor. Los pensamientos negativos sobre el dolor son una orientación negativa exagerada hacia estímulos nocivos y juegan un papel importante en experimentar y manejar el dolor. Esta sección consta de 13 afirmaciones que contienen una serie de pensamientos y sentimientos que uno puede experimentar al tener dolor. Usando las opciones, por favor indique en qué medida tiene esos pensamientos y sentimientos cuando está experimentando dolor. Esto ayudará a nuestros consejeros a sugerir el mejor curso de acción para manejar su dolor."
  },
  {
    "Summary notes ...": "Notas resumen..."
  },
  {
    "Patient is cleared for surgery": "El paciente está autorizado para la cirugía."
  },
  {
    "Patient is not cleared for surgery": "El paciente no está autorizado para la cirugía."
  },
  {
    "Patient can be cleared conditionally": "El paciente puede ser autorizado condicionalmente"
  },
  {
    "Does not apply": "No aplica"
  },
  {
    "Surgical Evaluation Result": "Resultado de la Evaluación Quirúrgica"
  },
  {
    "Counselor Notes": "Notas del consejero"
  },
  {
    "Anxiety Score": "Puntuación de ansiedad"
  },
  {
    "MINIMAL (0-4)/MILD (5-9)/MODERATE (10-14)/SEVERE (15+) symptoms of anxiety.": "Síntomas de ansiedad MÍNIMOS (0-4)/LEVES (5-9)/MODERADOS (10-14)/GRAVES (15+)"
  },
  {
    "Depression Score": "Puntuación de depresión"
  },
  {
    "MINIMAL (0-4)/MILD (5-9)/MODERATE (10-14)/MODERATELY SEVERE (15-19)/SEVERE (20-27)": "MÍNIMO (0-4) / LEVE (5-9) / MODERADO (10-14) / MODERADAMENTE SEVERO (15-19) / SEVERO (20-27)"
  },
  {
    "Pain Score": "Puntuación de dolor"
  },
  {
    "A total score of 30 or more represents clinically relevant levels of catastrophizing.": "Un puntaje total de 30 o más representa niveles clínicamente relevantes de catastrofismo."
  },
  {
    "Psychological Evaluation for General Mental Health Introduction": "Evaluación psicológica para la salud mental general Introducción"
  },
  {
    "Welcome to Skyler Health Psychological Evaluation for General Mental Health! We are committed to providing you with rapid appointments and counselor sessions as quickly as possible so that you can get back to life! In order to schedule a session with a counselor, please take 15 minutes to answer questions about your current health and medical history. You will then schedule a session with a counselor for a virtual video or phone call appointment. We guarantee that you will be very happy with your session or we will refund your fees. A final comprehensive report by the counselor will be sent to you (or your doctor if requested). Shall we begin?": "¡Bienvenido a la Evaluación Psicológica de Salud de Skyler Health para la Salud Mental General! ¡Estamos comprometidos en brindarle citas rápidas y sesiones con un consejero lo más pronto posible para que pueda volver a la vida! Para programar una sesión con un consejero, por favor tómese 15 minutos para responder preguntas sobre su estado de salud actual y su historial médico. Luego programará una sesión con un consejero para una cita virtual por videollamada o llamada telefónica. Garantizamos que quedará muy satisfecho con su sesión o le devolveremos sus honorarios. Se le enviará un informe final completo por parte del consejero (o a su médico si así lo solicita). ¿Empezamos?"
  },
  {
    "Please answer these questions about your ability to manage pain after a surgery or procedure. We are interested in learning about your pain management and coping skills so that we can make appropriate recommendations to you. Questions below are necessary for this evaluation.": "Por favor responda a estas preguntas sobre su habilidad para manejar el dolor después de una cirugía o procedimiento. Estamos interesados en conocer sobre sus habilidades de manejo del dolor y de afrontamiento para poder hacer recomendaciones adecuadas. Las preguntas a continuación son necesarias para esta evaluación."
  },
  {
    "Trauma Counseling Introduction": "Introducción a la Consejería de Trauma"
  },
  {
    "Welcome to Skyler Health Trauma Counseling! We are committed to providing you with rapid appointments and counselor sessions as quickly as possible so that you can get back to life! In order to schedule a session with a counselor, please take 15 minutes to answer questions about your current health and medical history. You will then schedule a session with a counselor for a virtual video or phone call appointment. We guarantee that you will be very happy with your session or we will refund your fees. A final comprehensive report by the counselor will be sent to you (or your doctor if requested). Shall we begin?": "¡Bienvenido a Skyler Health Trauma Counseling! Estamos comprometidos a brindarte citas rápidas y sesiones con un consejero lo más pronto posible para que puedas seguir con tu vida. Para programar una sesión con un consejero, por favor toma 15 minutos para responder preguntas sobre tu salud actual y tu historial médico. Luego programarás una sesión con un consejero para una cita virtual por video o teléfono. Garantizamos que quedarás muy satisfecho con tu sesión o te devolveremos tu tarifa. Un informe final comprensivo será enviado por el consejero a ti (o a tu médico si lo solicitas). ¿Comenzamos?"
  },
  {
    "Pain Counseling and Evaluation Introduction": "Introducción a la Consejería y Evaluación del Dolor"
  },
  {
    "Welcome to Skyler Health Pain Counseling and Evaluation! We are committed to providing you with rapid appointments and counselor sessions as quickly as possible so that you can get back to life! In order to schedule a session with a counselor, please take 15 minutes to answer questions about your current health and medical history. You will then schedule a session with a counselor for a virtual video or phone call appointment. We guarantee that you will be very happy with your session or we will refund your fees. A final comprehensive report by the counselor will be sent to you (or your doctor if requested). Shall we begin?": "¡Bienvenido a Skyler Health Pain Counseling and Evaluation! Estamos comprometidos a proporcionarle citas rápidas y sesiones con el consejero lo más pronto posible para que pueda volver a su vida. Para programar una sesión con un consejero, por favor tómese 15 minutos para responder preguntas sobre su estado de salud actual y su historial médico. Luego programará una sesión con un consejero para una cita virtual por video o telefónica. Garantizamos que estará muy satisfecho con su sesión o le reembolsaremos sus tarifas. Un informe integral final por parte del consejero será enviado a usted (o a su médico si así lo solicita). ¿Empecemos?"
  },
  {
    "Medication Titration Counseling Introduction": "Introducción al Asesoramiento sobre la Titulación de Medicamentos"
  },
  {
    "Welcome to Skyler Health Psychological Evaluation for Medication Titration Counseling! We are committed to providing you with rapid appointments and counselor sessions as quickly as possible so that you can get back to life! In order to schedule a session with a counselor, please take 15 minutes to answer questions about your current health and medical history. You will then schedule a session with a counselor for a virtual video or phone call appointment. We guarantee that you will be very happy with your session or we will refund your fees. A final comprehensive report by the counselor will be sent to you (or your doctor if requested). Shall we begin?": "¡Bienvenido a la Evaluación Psicológica de Salud Skyler para la Asesoría de Titulación de Medicamentos! ¡Estamos comprometidos en proporcionarle citas rápidas y sesiones de asesoramiento tan pronto como sea posible para que pueda volver a la vida! Para programar una sesión con un asesor, por favor tome 15 minutos para responder preguntas sobre su estado de salud actual e historial médico. Luego programará una cita con un asesor para una sesión de video virtual o llamada telefónica. Garantizamos que estará muy feliz con su sesión o le reembolsaremos sus tarifas. Un informe final completo del asesor le será enviado (o a su doctor si así lo solicita). ¿Empezamos?"
  },
  {
    "Welcome to Skyler Health Pain Counseling and Evaluation. We are committed to providing you with the most clinically accurate evaluation as quickly as possible so that you can get back to life! For your evaluation, please take 15 minutes to answer these questions about your current health and medical history. A Skyler Health counselor will review your answers, and then meet with you virtually to go over them. A final report will be produced by the counselor and shared with you or your referring doctor. Shall we begin?": "Bienvenido a Skyler Health Pain Counseling and Evaluation. Estamos comprometidos a brindarte la evaluación clínicamente más precisa lo más rápido posible para que puedas volver a la vida! Para tu evaluación, por favor, tómate 15 minutos para responder estas preguntas sobre tu salud actual e historial médico. Un consejero de Skyler Health revisará tus respuestas y luego se reunirá contigo virtualmente para repasarlas. Un informe final será producido por el consejero y compartido contigo o con tu médico referente. ¿Comenzamos?"
  },
  {
    "Counseling and Therapy for Mental Health Introduction": "Asesoramiento y Terapia para la Introducción de la Salud Mental."
  },
  {
    "Welcome to Skyler Health Counseling and Therapy for Mental Health! We are committed to providing you with rapid appointments and counselor sessions as quickly as possible so that you can get back to life! In order to schedule a session with a counselor, please take 15 minutes to answer questions about your current health and medical history. You will then schedule a session with a counselor for a virtual video or phone call appointment. We guarantee that you will be very happy with your session or we will refund your fees. A final comprehensive report by the counselor will be sent to you (or your doctor if requested). Shall we begin?": "¡Bienvenido a Skyler Health Counseling and Therapy for Mental Health! Estamos comprometidos a brindarte citas rápidas y sesiones con consejeros lo más pronto posible para que puedas volver a tu vida normal. Para programar una sesión con un consejero, por favor tómate 15 minutos para responder preguntas sobre tu salud actual e historial médico. Luego programarás una sesión con un consejero para una cita virtual por video o llamada telefónica. Garantizamos que estarás muy satisfecho con tu sesión o te devolveremos tu tarifa. Un informe final completo del consejero te será enviado (o a tu médico si lo solicitas). ¿Comenzamos?"
  },
  {
    "1 year": "1 año"
  },
  {
    "2 years": "2 años"
  },
  {
    "3 years": "3 años"
  },
  {
    "4 years": "4 años"
  },
  {
    "5 years": "5 años"
  },
  {
    "6 years": "6 años"
  },
  {
    "7 years": "7 años"
  },
  {
    "8 years": "8 años"
  },
  {
    "9 years": "9 años"
  },
  {
    "10 years": "10 años"
  },
  {
    "1-2 hours": "1-2 horas"
  },
  {
    "2-3 hours": "2-3 horas"
  },
  {
    "3-4 hours": "3-4 horas"
  },
  {
    "4-5 hours": "4-5 horas"
  },
  {
    "6-7 hours": "6-7 horas"
  },
  {
    "7-8 hours": "7-8 horas"
  },
  {
    "8-9 hours": "8-9 horas"
  },
  {
    "10-12 hours": "10-12 horas"
  },
  {
    "2 - 5 years": "2 - 5 años"
  },
  {
    "Here's a list of medical conditions to review if you like. <a href='/medical-conditions' target='_blank'>List of Medical Conditions</a>":"Aquí tienes una lista de condiciones médicas para revisar, si gustas.<a href='/medical-conditions?lang=es' target='_blank'> Lista de Condiciones Médicas </a>"
  }
] as const