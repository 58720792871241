import { PAYMENT_TYPE_DISPLAY } from "@/apps/common/constants";
import { formatDateOfBirth } from "@/apps/common/helpers/date";
import { ReferralType } from "@/apps/common/types/appointment-types";
import React, { useState, useEffect } from "react";

type PropType = {
  referral: ReferralType;
};

export default function ReferralDetails({ referral }: PropType) {

  const [language, setLanguage] = useState<string>()

  const LANGUAGES = [{id:'english', 
                      descp:"English"}, 
                      {id:'es',
                      descp:"Spanish"
                    },{id:'en',
                      descp:"English"
                    }]
  
  const mapLanguage = () =>{
    console.debug ('mapLanguage')

    LANGUAGES.find ( (lang) => {

      if (lang.id === referral.spokenLanguage){
        setLanguage(lang.descp)        
      }
    })
  }

 
  useEffect(() => {
    mapLanguage();
  }, []);

  return (
    <div className="table-responsive">
      <table className="table table-borderless mb-0 text-muted">
        <tbody>
          <tr>
            <th scope="row">State</th>
            <td>{referral.state?.name}</td>
          </tr>
          <tr>
            <th scope="row">DOB</th>
            <td>{formatDateOfBirth(referral.dateOfBirth)}</td>
          </tr>
          <tr>
            <th scope="row">First Name</th>
            <td>{referral.firstName}</td>
          </tr>
          <tr>
            <th scope="row">Last Name</th>
            <td>{referral.lastName}</td>
          </tr>
          <tr>
            <th scope="row">Email</th>
            <td>{referral.email}</td>
          </tr>
          <tr>
            <th scope="row">Phone</th>
            <td>{referral.phoneNumber}</td>
          </tr>
          <tr>
            <th scope="row">Referring Doctor</th>
            <td>{referral.referringDoctor}</td>
          </tr>
          <tr>
            <th scope="row">Service Type</th>
            <td>{referral.serviceType?.name}</td>
          </tr>
          <tr>
            <th scope="row">Payment By</th>
            <td>{PAYMENT_TYPE_DISPLAY[referral.paymentType]}</td>
          </tr>
          <tr>
            <th scope="row">Language Spoken</th>
            {/* <td>{referral.spokenLanguage}</td> */}
            <td>{language}</td>
          </tr>
          <tr>
            <th scope="row">Notes for Skyler Health</th>
            <td>{referral.notes}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
