import { generateForm } from "@modules/react-jsonschema-form-bootstrap";
import validator from "@rjsf/validator-ajv8";
import { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import { api } from "../api-client";
import { useProviderProfileFormConfig } from "../forms/ProviderProfile";
import { ServiceProviderType } from "../types/appointment-types";

const Form = generateForm<Partial<ServiceProviderType>>();

export default function ProviderProfileForm({
  mode,
  serviceProvider,
  updateData
}: {
  mode: "create" | "edit" | "preview";
  serviceProvider: Partial<ServiceProviderType>;
  updateData: (data: any) => void;
  // display: (value: boolean) => void;
}) {

  const [serviceProviderData, setServiceProviderData] = useState<Partial<ServiceProviderType>>();
  const [submitMessage, setSubmitMessage] = useState<string>();
  const [displaySubmitMessage, setDisplaySubmitMessage] = useState<boolean>(false);
  const { schema, uiSchema, customValidate, transformErrors } =
    useProviderProfileFormConfig({
      mode,
    });

  const showAlert = () => {
    setDisplaySubmitMessage(true)
    const timeoutId = setTimeout(() => {
      setDisplaySubmitMessage(false);
    }, 2000);
  }

  //console.debug ('service_provider_types - ' + JSON.stringify(serviceProvider?.service_provider_types))
  const setValues = () => {

    const data = {
      ...(serviceProvider
        ? {
          //...serviceProvider,
          npi: serviceProvider?.npi,
          status: serviceProvider.status ? serviceProvider.status : "pending",
          states: serviceProvider.states,
          serviceTypes: serviceProvider.serviceTypes,
          service_provider_types: serviceProvider?.service_provider_types,
          languages: serviceProvider?.languages
        }
        : {}
      )
    };

    //console.debug('setting values ' + JSON.stringify(data))
    return data
  }

  useEffect(() => {
    setServiceProviderData(setValues())
  }, [serviceProvider]);

  const onSubmit = (form: any) => {

    console.debug('submitting Provider Profile data - ' + JSON.stringify(form.formData))    
    const formData = form?.formData;
    if (!formData?.npi){
      formData.npi = ''
    }

    if (serviceProvider?.id) {
      api.serviceProviders.update(serviceProvider.id, form.formData).then(
        (res) => {
          if (res.id !== undefined) {
            api.serviceProviders.find(res.id)?.then(
              (resData) => {
                //console.debug('successfully updated service provider profile - ' + JSON.stringify(resData))
                setSubmitMessage("Provider details were updated successfully !")
                showAlert()
                updateData(resData),
                  setServiceProviderData(resData)
              }
            )
          }
        },
        (err) => {
          //console.debug('error updating service provider profile - ' + JSON.stringify(err)) 
          setSubmitMessage("An error occured while updating the Provider details. Please try again.")
          showAlert()
        }
      )
    }
  };

  return (
    <>
      {
        <>
          <br />
          {serviceProvider ?
            (<Form
              formContext={serviceProviderData}
              formData={serviceProviderData as Partial<ServiceProviderType>}
              schema={schema as any}
              uiSchema={uiSchema as any}
              validator={validator}
              showErrorList={false}
              customValidate={customValidate}
              noHtml5Validate
              transformErrors={transformErrors}
              onSubmit={onSubmit}
            ></Form>) : null
          }
          <br />
          <br />
          <Alert variant="secondary" className="mb-0 d-md-block d-sm-none" show={displaySubmitMessage} >
            {submitMessage}
          </Alert>
        </>

      }
    </>

  );

}
