import React, { useEffect, useState } from "react";
import { APPOINTMENT_STATUS, PAYMENT_TYPE } from "@/apps/common/constants";
import { useRequest } from "ahooks";
import { Form, Card, Tab, Tabs, Container, Row, Col, ButtonToolbar, ButtonGroup, Button } from 'react-bootstrap';
import TeleHealthLocationForm from "@/apps/common/components/HealthInsurance/TeleHealthLocation"
import HealthInsuranceForm from "@/apps/common/components/HealthInsurance/HealthInsurance"
import { api } from "@/apps/common/api-client";
//import { useTranslation } from "react-i18next";
import { PaymentLogType, MemberServiceType, ServiceProviderType, MemberEventType, ReferralType, TelehealthLocationType, UserType, HealthInsuranceType, EvaluationReportType, CPTCodeType } from "@/apps/common/types/appointment-types";
import MemberTimeline from "@/apps/common/pages/Timeline/TimelineProps";
import { ConfirmModal } from "@/apps/common/components/ConfirmModal/ConfirmModal"
import { Alert} from "react-bootstrap";
import CardHeader from "react-bootstrap/esm/CardHeader";
import { useNavigate } from "react-router-dom";


export default function MemberServiceDetails({ memberService }: { memberService: MemberServiceType | null }) {

    const [deepMemberService, setMemberService] = useState<MemberServiceType>()
    const [serviceProvider, setServiceProvider] = useState<ServiceProviderType>()
    const [memberEvents, setMemberEvents] = useState<MemberEventType[]>()
    const [referral, setReferral] = useState<ReferralType>()
    const [teleHealthLocationData, setTeleHealthLocationData] = useState<TelehealthLocationType>()
    const [healthInsurance, setHealthInsuranceData] = useState<HealthInsuranceType>()
    const [paymentLogs, setPaymentLogs] = useState<PaymentLogType[]>()
    const [user, setUser] = useState<UserType>()
    //const [formSubmitStatus, setFormSubmitStatus] = useState<boolean | null>(null)
    const [providerReport, setProviderReport] = useState<EvaluationReportType>()
    const [showPaymentTypeChangeConfirmDlg, setShowPaymentTypeChangeConfirmDlg] = useState<boolean>(false)
    const [copayAmount, setCopayAmount] = useState<number>(25.00)
    const [disableCopay, setDisableCopay] = useState<boolean>(false)
    const [copayMessage, setCopayMessage] = useState<string>()
    const [displayCopayAlert, setDisplayCopayAlert] = useState<boolean>(false)
    const [copayBtnText, setCopayBtnText] = useState<string>('Charge Copay')
    //const [showScheduleNewSession, setShowScheduleNewSession] = useState<boolean>(false)

    const { data: _deepMemberService } = useRequest(
        () =>
            fetchMemberService(),
        {
            //refreshDeps: [filters],
            debounceWait: 300,
            pollingInterval: 5000
        }
    );
    //console.debug ('referral ' + JSON.stringify(referral))
    async function fetchMemberService() {
        console.debug('fetchMemberService');

        if (memberService) {

            api.memberServices.findMemberService(memberService.id).then(
                (res) => {


                    //console.debug ('memberService ' + JSON.stringify(res))
                    //setReRender(true)
                    setMemberService(res)

                    //console.debug ('providerReport - ' + JSON.stringify(res?.providerReport) )
                    setProviderReport(res?.providerReport)
                    //console.debug ('User - ' + JSON.stringify(res?.user ))
                    //unstrapiMany<MemberEventType>(res?.events, "memberEvent")
                    setServiceProvider(res?.appointment?.timeSlot?.serviceProvider)
                    setMemberEvents(res?.events)
                    setReferral(res?.referral)
                    //console.debug ( 'Referral - ' + JSON.stringify(res?.referral?.serviceProvider?.user))
                    setTeleHealthLocationData(res?.user?.telehealth_location)
                    setUser(res?.user)
                    //console.debug('Health Insurance Data - ' + JSON.stringify(res?.user?.health_insurance))
                    setHealthInsuranceData(res?.user?.health_insurance)
                    setPaymentLogs(res?.paymentLogs)
                    // paymentLogs?.forEach ((log) => {
                    //     console.debug ('paymentLog - ' + JSON.stringify(log))
                    // })
                    //console.debug ('payment logs ' + JSON.stringify(res?.paymentLogs))
                    const paymentLog = paymentLogs?.find((log) =>
                        log.mode === "insurance-copay")

                    //console.debug (JSON.stringify(paymentLog))
                    if (!paymentLog) {
                        //console.debug ('Enablig copay since payment logs do not exist')
                        setDisableCopay(false)
                    }
                    else {
                        //console.debug ('Disabling copay since payment logs exists')
                        setDisableCopay(true)
                    }


                    return res
                }
            )
        }

    }

    const changePaymentType = () => {
        //console.debug ('Change Payment Type')
        const data = {
            paymentType: PAYMENT_TYPE.SELF
        };


        if ((memberService?.id) && (memberService?.appointment)) {

            api.appointments.updateStatus(memberService.appointment.id, { status: APPOINTMENT_STATUS.RESCHEDULED })
            api.memberEvents.createInsuranceDeniedEvent(memberService.id)
            api.memberServices.updatePaymentType(memberService.id, data).then(
                (res) => {
                    console.debug('api.memberServices')
                    setShowPaymentTypeChangeConfirmDlg(false)
                }, (error) => { console.error('Error occurred while updating paymentType') }
            )
        }
    }

    const cancelPaymentDlg = () => {
        setShowPaymentTypeChangeConfirmDlg(false)
    }

    const chargeCopay = () => {
        setDisableCopay(true)
        const paymentLog = deepMemberService?.paymentLogs?.find(
            (log) =>
                log.mode === "insurance-copay"
        );

        if (paymentLog) {
            setCopayMessage('A Copay amount has already been processed earlier.')
            showCopayAlert()
            setDisableCopay(false)
            return;
        }
        const defaultBtnTxt = copayBtnText;
        setCopayBtnText('Charging copay ...')

        const data = {
            memberServiceId: memberService!.id,
            amount: copayAmount
        }

        api.memberServices.chargeCopay(data).then((res) => {

            //console.debug ('Copay Successfull')
            setCopayMessage('Copay amount of $' + copayAmount + ' was charged successfully')
            showCopayAlert()
        },
            (res) => {
                console.error('Copay Failed')
                //setDisplaySpinner (false)
                //setSpinnerMessage('')
                setDisableCopay(false)

            }
        )
        setCopayBtnText(defaultBtnTxt)

    }

    const _disableCopay = () => {

        if (disableCopay === true) {
            //console.debug ('Disabling Copay button disableCopay = true')
            return true
        }

        if (deepMemberService?.paymentType === PAYMENT_TYPE.INSURANCE) {

            if ([APPOINTMENT_STATUS.CONFIRMED, APPOINTMENT_STATUS.DUE].includes(
                deepMemberService!.appointment!.status) === true) {

                // Check if copay logs exists in payment logs
                const paymentLog = paymentLogs?.find((log) =>
                    log.mode === "insurance-copay")
                if (paymentLog)
                    return true
                else
                    return false

            }
            else {
                return true
            }

        }
        else {
            //console.debug ('Disabling Copay button paymentType != PAYMENT_TYPE.INSURANCE')
            return true;
        }



    }

    const showCopayAlert = () => {
        setDisplayCopayAlert(true)
        setTimeout(() => {
            setDisplayCopayAlert(false);
        }, 2000);

    }

    const dateToString = (date: string | undefined) => {
        //console.debug ('Appt Date:' + JSON.stringify(date))

        if (!date)
            return "N/A"
        const _date = new Date(date)
        if (_date)
            return _date.toString()
    }

    // async function onSubmitHealthInsuranceForm(formData: any) {

    //     //console.debug('submitting HealthInsurance form - ' + JSON.stringify(formData))
    //     //setHealthInsuranceData(formData);

    //     const _healthInsurance = {
    //         firstLegalName: formData.firstLegalName,
    //         lastLegalName: formData.lastLegalName ? formData.lastLegalName : '',
    //         groupNumber: formData.groupNumber,
    //         memberId: formData.memberId,
    //         phoneNumber: formData.phoneNumber,
    //     }

    //     try {

    //         if (healthInsurance?.id) {
    //             console.debug('Updating an existing HealthInsurance')
    //             const data = {
    //                 ..._healthInsurance
    //             }

    //             await api.healthInsurance.update(healthInsurance.id, data).then((res) => {

    //                 setFormSubmitStatus(true)
    //                 setHealthInsuranceData(res)
    //             },
    //                 (err) => {
    //                     setFormSubmitStatus(false)
    //                 });
    //         }
    //         else {

    //             const a = {
    //                 ..._healthInsurance,
    //                 user: user?.id
    //             }
    //             await api.healthInsurance.create(a).then((res) => {

    //                 //setShowTeleHealthLocation(false)
    //                 //setSubmitMessage("Telehealth Location changes were saved successfully !")
    //                 //display(false)
    //                 setFormSubmitStatus(true)
    //             },
    //                 (err) => {
    //                     setFormSubmitStatus(false)
    //                 }
    //             )
    //         }

    //     } catch (err) {
    //         console.log(err);
    //     }

    // };


    const navigate = useNavigate();
    const scheduleNewSession = () => {
        navigate(`/newsession/${memberService?.id}`)

    }

    useEffect(() => {

    }, [_deepMemberService, deepMemberService]);

    return (
        <>
            <Tabs
                defaultActiveKey="home"
                id="justify-tab-example"
                className="mb-3"
                justify
            >
                <Tab eventKey="home" title="Member Details" >
                    <Card >
                        <Card.Header >Member Details:</Card.Header>
                        <Card.Body>
                            {/* <Row>
                                <Col className="d-flex">
                                    <Form.Text><strong>Member Details:</strong></Form.Text>
                                </Col>
                            </Row> */}
                            <Row>
                                <Col className="d-flex">
                                    <p className="px-1 text-secondary " style={{ fontSize: "small" }}>First Name:</p>
                                    <p className="px-1 text-primary" style={{ fontSize: "small" }}>{memberService?.user?.firstName}</p>
                                    {/* <Card.Text>{memberService?.user?.firstName}</Card.Text> */}
                                </Col>
                                <Col className="d-flex">
                                    <p className="px-1 text-secondary " style={{ fontSize: "small" }}>Last Name:</p>
                                    <p className="px-1 text-primary" style={{ fontSize: "small" }}>{memberService?.user?.lastName}</p>
                                    {/* <Card.Text>Last Name: {memberService?.user?.lastName}</Card.Text> */}
                                </Col>
                            </Row>
                            <Row>

                                <Col className="d-flex">
                                    <p className="px-1 text-secondary " style={{ fontSize: "small" }}>DoB:</p>
                                    <p className="px-1 text-primary" style={{ fontSize: "small" }}>{memberService?.user?.dateOfBirth}</p>

                                    {/* <Card.Text>DoB: {memberService?.user?.dateOfBirth}</Card.Text> */}
                                </Col>
                                <Col className="d-flex">
                                    <p className="px-1 text-secondary " style={{ fontSize: "small" }}>State:</p>
                                    <p className="px-1 text-primary" style={{ fontSize: "small" }}>{memberService?.user?.state?.name}</p>
                                    {/* <Card.Text>State: {memberService?.user?.state?.name}</Card.Text> */}
                                </Col>
                            </Row>
                            <Row>
                                <Col className="d-flex">
                                    <p className="px-1 text-secondary " style={{ fontSize: "small" }}>Phone:</p>
                                    <p className="px-1 text-primary" style={{ fontSize: "small" }}>{memberService?.user?.phoneNumber}</p>
                                    {/* <Card.Text>Phone: {memberService?.user?.phoneNumber}</Card.Text> */}
                                </Col>
                                <Col className="d-flex">
                                    <p className="px-1 text-secondary " style={{ fontSize: "small" }}>Email:</p>
                                    <p className="px-1 text-primary" style={{ fontSize: "small" }}>{memberService?.user?.email}</p>

                                    {/* <Card.Text>Email: {memberService?.user?.email}</Card.Text> */}
                                </Col>
                            </Row>
                            <Row>
                                <Col className="d-flex">
                                    <p className="px-1 text-secondary " style={{ fontSize: "small" }}>Spoken Language:</p>
                                    <p className="px-1 text-primary" style={{ fontSize: "small" }}>{memberService?.user?.spokenLanguage}</p>

                                    {/* <Card.Text>Spoken Language: {memberService?.user?.spokenLanguage}</Card.Text> */}
                                </Col>

                            </Row>
                        </Card.Body>
                    </Card>

                    <Card className="flex-fill" >
                        <CardHeader >Referral Details:</CardHeader>
                        <Card.Body>
                            {referral?.clinic ?
                                (
                                    <>
                                    <Row>
                                        <Col className="d-flex">
                                            {/* <Form.Text>Referring Clinic: {referral?.clinic?.name}</Form.Text> */}
                                            <p className="px-1 text-secondary " style={{ fontSize: "small" }}>Referring Clinic:</p>
                                            <p className="px-1 text-primary" style={{ fontSize: "small" }}>{referral?.clinic?.name}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="d-flex">
                                            {/* <Form.Text>Referral Notes: {referral?.notes}</Form.Text> */}
                                            <p className="px-1 text-secondary " style={{ fontSize: "small" }}>Referral Notes:</p>
                                            <p className="px-1 text-primary" style={{ fontSize: "small" }}>{referral?.notes}</p>
                                        </Col>
                                    </Row>
                                    </>
                                ) :
                                null
                            }
                            
                            {referral?.serviceProvider ?
                                (
                                    <>
                                    <Row>
                                        <Col className="d-flex">
                                            {/* <Form.Text>Referring Clinic: {referral?.clinic?.name}</Form.Text> */}
                                            <p className="px-1 text-secondary " style={{ fontSize: "small" }}>Referring Service Provider:</p>
                                            <p className="px-1 text-primary" style={{ fontSize: "small" }}> {referral?.serviceProvider?.user?.firstName} {referral?.serviceProvider?.user?.lastName}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="d-flex">
                                            {/* <Form.Text>Referral Notes: {referral?.notes}</Form.Text> */}
                                            <p className="px-1 text-secondary " style={{ fontSize: "small" }}>Referral Notes:</p>
                                            <p className="px-1 text-primary" style={{ fontSize: "small" }}>{referral?.notes}</p>
                                        </Col>
                                    </Row>
                                    </>
                                ) :
                                null
                            }
            
                        </Card.Body>
                    </Card>

                    <Card className="flex-fill" >
                        <CardHeader>Appointment Details:</CardHeader>
                        <Card.Body>
                            {/* <Row>
                                <Col className="d-flex">
                                    <Form.Text><strong></strong></Form.Text>
                                </Col>
                            </Row> */}
                            <Row>
                                <Col className="d-flex">
                                    <p className="px-1 text-secondary " style={{ fontSize: "small" }}>Appointment Date:</p>
                                    <p className="px-1 text-primary" style={{ fontSize: "small" }}>{dateToString(memberService?.appointment?.timeSlot?.startDate)}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="d-flex">
                                    <p className="px-1 text-secondary " style={{ fontSize: "small" }}>Service Provider:</p>
                                    <p className="px-1 text-primary" style={{ fontSize: "small" }}>{serviceProvider ? serviceProvider?.user?.firstName + ' ' + serviceProvider?.user?.lastName : "Not Assigned"}</p>
                                    {/* <Card.Text>Service Provider: {serviceProvider ? serviceProvider?.user?.firstName + ' ' + serviceProvider?.user?.lastName : "Not Assigned"}</Card.Text> */}
                                </Col>
                            </Row>
                            <Row>
                                <Col className="d-flex">
                                    <p className="px-1 text-secondary " style={{ fontSize: "small" }}>Session Duration:</p>
                                    <p className="px-1 text-primary" style={{ fontSize: "small" }}>{memberService?.sessionDuration} minutes</p>

                                    {/* <Card.Text>Session Duration: {memberService?.sessionDuration} </Card.Text> */}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                        <CardHeader>Payment Details:</CardHeader>
                        <Card.Body>
                            {/* <Row>
                                <Col className="d-flex">
                                    <Form.Text><strong>Payment Details:</strong></Form.Text>
                                </Col>
                            </Row> */}
                            <Row>
                                <Col className="d-flex">
                                    <p className="px-1 text-secondary " style={{ fontSize: "small" }}>Payment Type:</p>
                                    <p className="px-1 text-primary" style={{ fontSize: "small" }}>{memberService?.paymentType}</p>

                                    {/* <Card.Text>Payment Type: {memberService?.paymentType}</Card.Text> */}
                                </Col>

                            </Row>
                            <Row>
                                <Col className="d-flex">
                                    <p className="px-1 text-secondary " style={{ fontSize: "small" }}>Charges:</p>
                                    {/* <Card.Subtitle>Charges:</Card.Subtitle> */}
                                </Col>
                            </Row>
                            <Row>
                                {deepMemberService?.paymentLogs &&
                                    deepMemberService.paymentLogs.map((v: PaymentLogType, i: number) => (
                                        <Row>
                                            <Col className="d-flex">
                                                <p className="px-1 text-primary" style={{ fontSize: "small" }}>${parseFloat((v?.amount / 100).toString()).toFixed(2)}</p>
                                                {/* <Form.Text>${parseFloat((v?.amount / 100).toString()).toFixed(2)}</Form.Text> */}
                                            </Col>
                                            <Col className="d-flex">
                                                <p className="px-1 text-primary" style={{ fontSize: "small" }}>{v?.mode}</p>
                                            </Col>
                                            <Col className="d-flex">
                                                <p className="px-1 text-primary" style={{ fontSize: "small" }}>{v?.status}</p>
                                                {/* <Form.Text>{v?.status}</Form.Text> */}
                                            </Col>
                                            <Col className="d-flex">
                                                <p className="px-1 text-primary" style={{ fontSize: "small" }}>{new Date(v?.updatedAt).toString()}</p>
                                                {/* <Form.Text>{new Date(v?.updatedAt).toString()}</Form.Text> */}
                                            </Col>
                                            <Col className="d-flex">
                                                <p className="px-1 text-primary" style={{ fontSize: "small" }}>{v?.paymentMethod?.brand}</p>
                                                {/* <Form.Text>{v?.paymentMethod?.brand}</Form.Text> */}
                                            </Col>
                                            <Col className="d-flex">
                                                <p className="px-1 text-primary" style={{ fontSize: "small" }}>{v?.paymentMethod?.last4}</p>
                                                {/* <Form.Text>{v?.paymentMethod?.last4}</Form.Text> */}
                                            </Col>
                                        </Row>

                                    ))
                                }
                            </Row>
                        </Card.Body>
                    </Card>

                    <Row>
                        <Col className="d-flex">
                            <ButtonToolbar>
                                <ButtonGroup className="me-2">
                                    <Button onClick={() => scheduleNewSession()}>
                                        Schedule New Session
                                    </Button>
                                </ButtonGroup>
                                {/* <ButtonGroup className="me-2">
                                    <Button                                        
                                        onClick={chargeCopay}></Button>
                                </ButtonGroup> */}
                            </ButtonToolbar>
                        </Col>
                    </Row>
                   

                </Tab>

                <Tab eventKey="healthInsurance" title="HealthInsurance">
                    {user ? (
                        <Card.Body>
                            {user ?
                                // (<HealthInsuranceForm mode={"edit"} healthInsurance={healthInsurance} user={user} onSubmitForm={onSubmitHealthInsuranceForm} formSubmitStatus={formSubmitStatus} />) :
                                (<HealthInsuranceForm mode={"edit"} healthInsurance={healthInsurance} user={user} display={()=>{}}/>) :
                                null
                            }
                        </Card.Body>
                    ) : null
                    }
                    <Card.Body>
                        {deepMemberService?.appointment ? (
                            <>
                                <Row>
                                    <Col className="d-flex">
                                        <ButtonToolbar aria-label="Toolbar with button groups">
                                            <ButtonGroup className="me-2" aria-label="First group">
                                                <Button disabled={(
                                                    ((deepMemberService?.paymentType !== PAYMENT_TYPE.INSURANCE) || (![APPOINTMENT_STATUS.CONFIRMED, APPOINTMENT_STATUS.DUE].includes(
                                                        deepMemberService!.appointment!.status)) &&
                                                        (memberService?.paymentType !== PAYMENT_TYPE.INSURANCE))
                                                )} onClick={() => setShowPaymentTypeChangeConfirmDlg(true)}>
                                                    Process Insurance Verication Failure
                                                </Button>
                                            </ButtonGroup>
                                            <ButtonGroup className="me-2" aria-label="Copay">
                                                <Button
                                                    disabled={_disableCopay()}
                                                    onClick={chargeCopay}>{copayBtnText}</Button>
                                            </ButtonGroup>
                                        </ButtonToolbar>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex">
                                        <Form.Group className="mb-3">
                                            <Form.Text><strong>Copay Amount ($):</strong></Form.Text>
                                            <Form.Control size='sm' as="input" defaultValue={copayAmount} onChange={(e: any) => {
                                                setCopayAmount(e.target.value);

                                            }} />
                                        </Form.Group>
                                        <Alert variant="secondary" className="mb-0 d-md-block d-sm-none" show={displayCopayAlert} >
                                            {copayMessage}
                                        </Alert>
                                    </Col>
                                </Row>
                            </>
                        ) : null
                        }

                    </Card.Body>
                </Tab>


                <Tab eventKey="teleHealthLocation" title="TeleHealth Location">

                    { user?.state ?  
                    (<Card>
                        <Card.Body>
                            <TeleHealthLocationForm mode="edit" teleHealthLocation={teleHealthLocationData!} user={user} display={() => { }} />
                        </Card.Body>

                    </Card>) : null
                    }
                </Tab>

                <Tab eventKey="memberEvents" title="Member Events">
                    <Card>
                        <Card.Body>
                            {memberEvents ? (<MemberTimeline memberService={deepMemberService} memberEvents={memberEvents} />)
                                : (
                                    <>
                                        <strong> No Events</strong>
                                    </>)}
                        </Card.Body>
                    </Card>
                </Tab>

                {providerReport ?
                    (<Tab eventKey="serviceReports" title="Member Reports">
                        <Card>
                            <Container>

                                <Row>
                                    <Col className="d-flex"><Form.Text><strong>Service Report Status: </strong></Form.Text></Col>
                                    <Col className="d-flex"><Form.Text>{providerReport?.status}</Form.Text></Col>
                                </Row>

                                <Row>
                                    <Col className="d-flex"><Form.Text><strong>Service Report: </strong></Form.Text></Col>
                                    {providerReport?.document?.url ? (
                                        <Col className="d-flex"><Form.Text><a href={providerReport?.document?.url} target={'_blank'}>Download Service Report</a></Form.Text></Col>) :
                                        (<Col className="d-flex"><Form.Text>Report Not Generated</Form.Text></Col>)
                                    }
                                </Row>

                                <Row><Form.Text><strong>Diagnosis Code(s) :</strong></Form.Text></Row>
                                {providerReport.cpt_codes &&
                                    providerReport.cpt_codes.map((v: CPTCodeType, i: number) => (
                                        <Row>
                                            <Col className="d-flex"><Form.Text>{v.code}</Form.Text></Col>
                                            <Col className="d-flex"><Form.Text>{v.description}</Form.Text></Col>
                                        </Row>

                                    ))
                                }


                            </Container>
                        </Card>
                    </Tab>
                    )
                    : null
                }

                {/* <Tab eventKey="contact" title="Contact" disabled>
        Tab content for Contact
      </Tab> */}
            </Tabs>

            <ConfirmModal show={showPaymentTypeChangeConfirmDlg} loading={false} title={'Confirm Payment Type Change'} message={'Please confirm changing the service to Self Pay'} onClickOk={changePaymentType} onHide={cancelPaymentDlg} />
            {/* <Modal show={showScheduleNewSession}>
                    <ScheduleAppointment memberService={memberService!}/>
            </Modal> */}
        </>
    )
}