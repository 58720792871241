import { AxiosInstance } from "axios";
import { reverseFormatDateOfBirth } from "../../helpers/date";
import { unstrapiMany, unstrapiOne } from "../../helpers/strapi";
import { MemberEventType, ReferralType } from "../../types/appointment-types";
import { BaseApiModule } from "./base.module";

export class ReferralsApiModule extends BaseApiModule {
  constructor(client: AxiosInstance) {
    super(client, "api");
  }

  create(data: ReferralType) {
    return this.client
      .post("api/referrals", { data })
      .then((res) => unstrapiOne<ReferralType>(res.data, "referral"));
  }

  update(id: number, data: ReferralType) {
    return this.client
      .put(`api/referrals/${id}`, { data })
      .then((res) => unstrapiOne<ReferralType>(res.data, "referral"));
  }

  findMany({
    query,
    filters,
    populate,
    pageSize = 10
  }: {
    query?: string;
    filters?: any;
    populate?: string[];
    pageSize?: number;
  } = {}) {
    const queryFilter: any = [];

    query = query?.trim();

    if (query?.length) {
      queryFilter.push(
        ...query.split(/\W+/).flatMap((word) =>
          ["firstName", "lastName", "email"].map((field) => ({
            [field]: { $containsi: word }
          }))
        )
      );

      const dateOfBirth = reverseFormatDateOfBirth(query);
      if (dateOfBirth) {
        queryFilter.push({
          dateOfBirth
        });
      }

      const phoneNumber = query.replace(/[^+\d]+/g, "");
      if (phoneNumber.length > 5) {
        queryFilter.push({
          phoneNumber: { $containsi: phoneNumber }
        });
      }
    }

    const { status, ...restFilters } = filters ?? {};

    return this.client
      .get(`api/referrals`, {
        params: {
          filters: {
            $or: queryFilter,
            memberService: {
              appointment: {
                status: {
                  $in: status
                }
              }
            },
            ...restFilters
          },
          sort: "createdAt:DESC",
          pagination: { pageSize },
          populate
        }
      })
      .then((res) => unstrapiMany<ReferralType>(res.data, "referral"));
  }

  getById(id: number, { populate }: { populate?: string[] } = {}) {
    return this.client
      .get(`api/referrals/${id}`, {
        params: {
          populate
        }
      })
      .then((res) => unstrapiOne<ReferralType>(res.data, "referral"));
  }

  getEvents(referralId: number) {
    return this.client
      .get("api/member-events", {
        params: {
          filters: { memberReferral: referralId, level: "info" },
          sort: "createdAt:DESC"
        }
      })
      .then((res) => unstrapiMany<MemberEventType>(res.data, "memberEvent"));
  }
}
