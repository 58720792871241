import { api } from "@/apps/common/api-client";
import { unstrapiMany } from "@/apps/common/helpers/strapi";
import { StateType } from "@/apps/common/types/appointment-types";
import { useRequest } from "ahooks";
import FeatherIcon from "feather-icons-react";
import Select from "react-select";

export default function StateSelector({
  value,
  onChange
}: {
  name: string;
  value: StateType[];
  onChange: (value: StateType[]) => void;
}) {
  const { data: states = [] } = useRequest(
    () =>
      api.auth
        .fetchStates()
        .then((response) => unstrapiMany(response.data, "state")),
    {
      cacheKey: "states"
    }
  );

  return (
    <div className="position-relative input-group">
      <span className="input-group-text">
        <FeatherIcon icon="map-pin" />
      </span>

      <Select
        isMulti
        options={states}
        onChange={(states) => onChange(states as StateType[])}
        defaultValue={value}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => String(option.id)}
        styles={{
          control: (base) => ({
            ...base,
            borderWidth: 0
          })
        }}
        className="form-control"
      />
    </div>
  );
}
