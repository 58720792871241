import { api } from "@/apps/common/api-client";
import { PageTitle } from "@/apps/common/components";
import { formatDate, getUserTimezone } from "@/apps/common/helpers/date";
import { useAuthStore } from "@/apps/common/store/useAuthStore";
import { TimeSlotsType } from "@/apps/common/store/useCommonStore";
import Calendar from "@/apps/member/components/Calendar/Calendar";
import ConfirmAppointmentModal from "@/apps/member/components/Calendar/ConfirmAppointmentModal";
import { EventData } from "@/apps/member/components/Calendar/interfaces";
import { EventClickArg } from "@fullcalendar/core/index.js";
import { useBoolean, useRequest } from "ahooks";
import { startCase } from "lodash-es";
import { useCallback, useState, useEffect } from "react";
import { Card, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import MemberSessionDetails from "../../components/MemberSessionDetails";

export default function RescheduleAppointmentPage({ mode }: { mode: string }) {


  const [slotType, setSlotType] = useState<number|undefined>(undefined)
  const { id } = useParams();

  const { user } = useAuthStore();

  const timeZone = getUserTimezone(user);

  const navigate = useNavigate();

  const isNewAppointment = mode === "new";

  const { data: appointment, loading } = useRequest(() =>
    api.appointments.getById(Number(id))
  );

  const { data: timeSlots, loading: slotsLoading } = useRequest(() =>
    api.appointments.fetchMemberAvailableTimeSlots(undefined,slotType)
  );

  const [
    rescheduleLoading,
    { setTrue: setRescheduleLoading, setFalse: doneRescheduleLoading }
  ] = useBoolean();

  const onConfirmAppointment = useCallback(
    async (id: string) => {
      try {
        setRescheduleLoading();
        if (isNewAppointment) {
          const memberService = await api.memberServices.startNewSession({
            timeSlotId: id,
            memberServiceId: appointment!.memberService!.id
          });
          navigate(`/appointments/${memberService?.appointment!.id}`);
        } else {
          await api.appointments.reschedule(appointment!.id, {
            timeSlotId: id
          });
          navigate(`/appointments/${appointment!.id}`);
        }
      } catch (err) {
        doneRescheduleLoading();
        console.log(err);
      }
    },
    [
      appointment,
      doneRescheduleLoading,
      isNewAppointment,
      navigate,
      setRescheduleLoading
    ]
  );

  const memberService = appointment?.memberService;

  const [eventData, setEventData] = useState<EventData>({});


  useEffect(()=>{

    if (memberService){
      console.debug ('SERVICE TYPE' + memberService!.serviceType!.name)
      if (['Psychological Evaluation for General Mental Health', 
           'Psychological Evaluation for Surgical Clearance'].includes(memberService!.serviceType!.name)){
          console.debug ('Selecting all slots)')
          setSlotType(undefined)
        }
      else{
        console.debug ('Setting 60 min slot')
        setSlotType(60)
      }

    }
    
  }, [memberService])

  const [
    confirmationModalVisible,
    { setTrue: showConfirmationModal, setFalse: hideConfirmationModal }
  ] = useBoolean();

  const onEventClick = useCallback(
    (arg: EventClickArg) => {
      console.log(JSON.stringify(timeSlots));
      const filter: TimeSlotsType[] = timeSlots!.filter(
        ({ id }: { id: string }) => id === arg.event.id
      );
      const event = {
        id: String(arg.event.id),
        title: arg.event.title,
        className: arg.event.classNames[0],
        time: `${formatDate(filter[0]?.start, "hh:mm a")} - ${formatDate(
          filter[0]?.end,
          "hh:mm a"
        )}`,
        timezone: timeZone,
        date: formatDate(filter[0]?.start, "LLLL dd, yyyy (cccc)"),
        serviceProvider: startCase(memberService!.serviceProviderType),
        apptType: filter[0].type
      };

      setEventData(event);
      showConfirmationModal();
    },
    [memberService, showConfirmationModal, timeSlots, timeZone]
  );

  return (
    <>
      <PageTitle
        breadCrumbItems={[{ label: "Appointment", path: "/" }]}
        title={
          isNewAppointment ? "Schedule Appointment" : "Reschedule Appointment"
        }
      />

      {loading ? (
        <div className="text-center p-4 mt-4">
          <Spinner
            style={{ width: 100, height: 100 }}
            animation="border"
            variant="info"
          />
        </div>
      ) : null}

      {appointment ? (
        <>
          <Card>
            <Card.Body>
              <MemberSessionDetails
                appointment={appointment}
                showActions={false}
              />
            </Card.Body>
          </Card>
        </>
      ) : null}

      {timeSlots !== undefined ? (
        <Calendar
          onEventClick={onEventClick}
          events={slotsLoading ? [] : timeSlots}
          timeZone={timeZone}
        />
      ) : (
        <Spinner />
      )}

      {confirmationModalVisible ? (
        <ConfirmAppointmentModal
          loading={rescheduleLoading}
          isOpen={confirmationModalVisible}
          onClose={hideConfirmationModal}
          eventData={eventData}
          onConfirmAppointment={onConfirmAppointment}
        />
      ) : null}
    </>
  );
}
