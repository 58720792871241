import { DateTime } from "luxon";
import { useAuthStore } from "../store/useAuthStore";
import { LegacyUserType } from "../api-client/api-client.types";

export const DEFAULT_TIMEZONE = "America/Los_Angeles";

export const DISPLAY_TIMEZONES = {
  "America/Phoenix": "Arizona Time",
  "America/Chicago": "Central Time",
  "America/Los_Angeles": "Pacific Time",
  "America/New_York": "Eastern Time",
  "America/Denver": "Mountain Time",
  "Asia/Karachi": "Pakistan Time"
};

export const DATE_FORMATS = {
  DATE_ONLY: "MM/dd/yyyy"
};

export function formatDate(
  date: string | Date,
  format: string = "EEE, dd MMM yyyy, hh:mm a z",
  timezone?: string
) {
  timezone ??= getUserTimezone();

  let formattedDate = null;
  if (date instanceof Date) {
    formattedDate = DateTime.fromJSDate(date, {
      zone: timezone
    }).toFormat(format);
  } else {
    formattedDate = DateTime.fromISO(date, {
      zone: timezone
    }).toFormat(format);
  }

  if (
    DISPLAY_TIMEZONES[timezone as keyof typeof DISPLAY_TIMEZONES] &&
    timezone !== "z"
  ) {
    formattedDate = formattedDate.replace(
      timezone,
      DISPLAY_TIMEZONES[timezone as keyof typeof DISPLAY_TIMEZONES] ?? timezone
    );
  }

  return formattedDate;
}

export function formatShortDate(
  date: string | Date,
  format: string = "EEE, dd MMM yyyy",
  timezone?: string
) {
  return formatDate(date, format, timezone);
}

export function getUserTimezone(
  user?: LegacyUserType,
  { formatForDisplay = false } = {}
) {
  if (!user) {
    user = useAuthStore.getState()?.user;
  }

  let userTimezone = user?.timezone;
  if (!userTimezone?.length) {
    userTimezone =
      Intl.DateTimeFormat().resolvedOptions().timeZone || DEFAULT_TIMEZONE;
  }

  if (formatForDisplay) {
    userTimezone =
      DISPLAY_TIMEZONES[userTimezone as keyof typeof DISPLAY_TIMEZONES] ??
      userTimezone;
  }

  return userTimezone;
}

export function formatDateOfBirth(date: string) {
  //console.debug ('DATE - ' + date)
  const resp =  DateTime.fromFormat(date, "yyyy-MM-dd").toFormat(
    DATE_FORMATS.DATE_ONLY
  );
  //console.debug ('Formatted DOB ' + resp)
  return resp
}

export function reverseFormatDateOfBirth(date: string) {
  const parsedDate = DateTime.fromFormat(date, "MM/dd/yyyy");
  if (parsedDate.isValid) {
    //const _date = parsedDate.toFormat("MM-dd-yyyy");
    const _date = parsedDate.toFormat("yyyy-MM-dd");
    //console.debug ('Formatted Date ' + _date)
    return _date
  }

  return null;
}

export function isValidDateFormat(date: string, format: string) {
  return DateTime.fromFormat(date, format).isValid;
}
